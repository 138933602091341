const state = () => ({
  status: 1, // 1.- Normal / 2.- Loading / 3.- Waiting for response / 4.- Error
  current_interface: 1, // 1.- step 1 // 2.- step 2 // 3.- step 3 // 4.- step 4 // 5.- summary table // 6.- Map select source // 7.- Map select destination // 8.- Display results
  get_properties: false
})

const mutations = {
  set_status(state, data){
    state.status = data
  },
  set_current_interface(state, data){
    state.current_interface = data
  },
  set_get_properties(state, data){
    state.get_properties = data
  },
}

const actions = {
  change_status({ commit, getters }, status) {
    if(getters['status'] == 3 && status == 2) {
      // Do nothing
    }
    else {
      commit("set_status", status)
    }   
  },
  change_current_interface({ dispatch, commit, getters }, current_interface) {
    commit("set_current_interface", current_interface)
    if (parseInt(current_interface) > 2 && getters['get_properties']) {
      dispatch("urn/get_sea_properties", '', { root: true });
    }
  },
  change_get_properties({ commit }, data) {
    commit("set_get_properties", data)
  },
}

 const getters = {
    status: state => state.status,
    current_interface: state => state.current_interface,
    get_properties: state => state.get_properties,
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }