<template>
  <div>
    <div v-if="status==1" class="card-header-1">OVERVIEW</div>
    <div class="card-body-1">
      <div class="row h-100">
        <div v-if="status==1" class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(4)"> &lt; </div>
        <div class="col-sm-10">

          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation v-if="status==1"></navigation>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 my-5 overview-results">
              <table style="width: 100%">
                <tr class="tr-border-bottom">
                  <td class="overview-row-title">Source</td>
                  <td><span class="overview-param-title">Long:</span></td>
                  <td v-if="pos_emitter.x" class="td-border-right"><span>{{ pos_emitter.x.toFixed(4) }} º</span></td>
                  <td v-else class="td-border-right"><span>{{ pos_emitter.x }}</span></td>
                  <td><span class="overview-param-title">Lat:</span></td>
                  <td v-if="pos_emitter.y" class="td-border-right"><span>{{ pos_emitter.y.toFixed(4) }} º</span></td>
                  <td v-else class="td-border-right"><span>{{ pos_emitter.y }}</span></td>
                  <td><span class="overview-param-title">Depth:</span></td>
                  <td><span>{{ pos_emitter.z }}m</span></td>
                </tr>
                
                <tr>
                  <td class="overview-row-title">Receiver</td>
                  <td ><span class="overview-param-title">Long:</span></td>
                  <td v-if="pos_receiver.x" class="td-border-right"><span>{{ pos_receiver.x.toFixed(4) }} º</span></td>
                  <td v-else class="td-border-right"><span>{{ pos_receiver.x}}</span></td>
                  <td><span class="overview-param-title">Lat:</span></td>
                  <td v-if="pos_receiver.y" class="td-border-right"><span>{{ pos_receiver.y.toFixed(4) }} º</span></td>
                  <td v-else class="td-border-right"><span>{{ pos_receiver.y }}</span></td>
                  <td><span class="overview-param-title">Depth:</span></td>
                  <td><span>{{ pos_receiver.z }}m</span></td>
                </tr>
                <tr  class="tr-border-bottom">
                  <td class="overview-row-title"></td>
                  <td><span class="overview-param-title">Dist. X:</span></td>
                  <td class="td-border-right"><span>{{ pos_receiver_cartesian.x }}m</span></td>
                  <td><span class="overview-param-title">Dist. Y:</span></td>
                  <td class="td-border-right"><span>{{ pos_receiver_cartesian.y }}m</span></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr  class="tr-border-bottom">
                  <td class="overview-row-title">Spectrum</td>
                  <td><span class="overview-param-title">Initial frequency:</span></td>
                  <td class="td-border-right"><span>{{ f_ini }} Hz</span></td>
                  <td><span class="overview-param-title">Final frequency:</span></td>
                  <td class="td-border-right"><span>{{ f_end }} Hz</span></td>
                  <td><span class="overview-param-title">Frequency step:</span></td>
                  <td><span>{{ f_step_text }}</span></td>
                </tr>

                <tr  class="tr-border-bottom">
                  <td class="overview-row-title">Model</td>
                  <td><span class="overview-param-title">{{ modelCommonName(model) }}</span></td>
                  <td class="td-border-right"></td>
                  <td></td>
                  <td class="td-border-right"></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td class="overview-row-title">Seabed param.</td>
                  <td><span class="overview-param-title">Density:</span></td>
                  <td v-if="sea_properties.lecho" class="td-border-right"><span>{{sea_properties.lecho[0]}} g/cm³</span></td>
                  <td v-else class="td-border-right"></td>
                  <td><span class="overview-param-title">Longitudinal wave speed:</span></td>
                  <td v-if="sea_properties.lecho" class="td-border-right"><span>{{sea_properties.lecho[1]}} m/s</span></td>
                  <td v-else class="td-border-right"></td>
                  <td><span class="overview-param-title">Transverse wave speed:</span></td>
                  <td v-if="sea_properties.lecho"><span>{{sea_properties.lecho[2]}} m/s</span></td>
                  <td v-else></td>
                </tr>
                <tr  class="tr-border-bottom">
                  <td class="overview-row-title"></td>
                  <td><span class="overview-param-title">Long. wave absorption:</span></td>
                  <td v-if="sea_properties.lecho" class="td-border-right"><span>{{sea_properties.lecho[3]}} dB/λ</span></td>
                  <td v-else class="td-border-right"></td>
                  <td><span class="overview-param-title">Trans. wave absorption:</span></td>
                  <td v-if="sea_properties.lecho" class="td-border-right"><span>{{sea_properties.lecho[4]}} dB/λ</span></td>
                  <td v-else class="td-border-right"></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr  class="tr-border-bottom">
                  <td class="overview-row-title">SSP</td>
                  <td><span class="overview-param-title">Avg. depth:</span></td>
                  <td v-if="mean_bty" class="td-border-right"><span>{{parseFloat(mean_bty).toFixed(2)}} m</span></td>
                  <td v-else class="td-border-right"></td>
                  <td><span class="overview-param-title">Avg. ssp:</span></td>
                  <td v-if="mean_ssp" class="td-border-right"><span>{{parseFloat(mean_ssp).toFixed(2)}} m/s</span></td>
                  <td v-else class="td-border-right"></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div> 
          </div>

          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6 text-center">
              <div v-if="status==1" class="button-calculate" v-on:click="calculate">Calculate</div>
            </div>
            <div class="col-sm-3"></div>
          </div>
          <div class="row error-validation">
              <div class="col-sm-12 text-center">
                <div  v-if="this.error_msg == true">
                  {{error_msg_text}}
                </div>
              </div>
          </div>
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow"> <!--&gt;--> </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Components and services
import navigation from '@/components/URN/navigation.vue'
import { modelName } from '@/services/helper'
import { getBathymetry } from '@/services/api'

export default {
  name: 'input5Overview',
  components: {
    navigation
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      pos_emitter: 'urn/pos_emitter',
      pos_receiver: 'urn/pos_receiver',
      f_ini: 'urn/f_ini',
      f_end: 'urn/f_end',
      f_step: 'urn/f_step',
      model: 'urn/model',
      form_validator: 'urn/form_validator',
      pos_receiver_cartesian: 'urn/pos_receiver_cartesian',
      current_distance: 'urn/current_distance',
      sea_properties: 'urn/sea_properties',
      bathymetry: 'urn/bathymetry'
    }),
  },
  data() {
    return {
      error_msg: false,
      error_msg_text: '',
      f_step_text: '',
      bty: [],
      mean_bty: '',
      mean_ssp: ''
    }
  },
  mounted () {
    this.setDisplayNames()
    this.getBathymetry()
    this.calculateMeans()
  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },

    calculate: function () { 
      let is_valid = true
      for (let item in this.form_validator) {
        //console.log(item, this.form_validator[item])
        if (this.form_validator[item] == false) is_valid = false 
      }
      let is_heavy_process = true
      if (is_valid) {
        is_heavy_process = this.checkHeavyProcess()
      }
      if (is_valid && !is_heavy_process) {
          this.$store.dispatch('urn/calculate')
          this.error_msg = false
      }  else {
        this.error_msg = true
        if(!is_valid) {
          this.error_msg_text = 'All input fields must be completed, and contain valid data.'
        }
        else if (is_heavy_process) {
          //this.error_msg_text = 'Too heavy to process. Please try to decrease frequency, distance and / or depth.'
        }
      } 
    },
    setDisplayNames: function() {
      // Freq Step
      if(this.f_step == 1) {
        this.f_step_text = 'Octaves'
      }
      else if(this.f_step == 2) {
        this.f_step_text = '1/3 Octaves'
      }
      else if(this.f_step == 3) {
        this.f_step_text = 'Decidecades'
      }
    },
    modelCommonName: function (model) {
        return modelName(model)
    },
    checkHeavyProcess: function () {
      if (this.model == "SimplePE") {
        if (this.bty.length > 0){
          let max_bty = Math.abs(Math.min(...this.bty))
          let max_f = parseFloat(this.f_end)
          let dist = parseFloat(this.current_distance)

          if(max_f > 2000) {
            this.error_msg_text ="Too heavy to process. Please try frequencies under 2KHz"
            return true
          }
          else if (max_f > 1000 && dist > 1000) {
            this.error_msg_text ="Too heavy to process. Please try a distance under 1000m (current: " + dist.toFixed(0) + "m)"
            return true
          }
          else if (max_f > 1000 && max_bty > 3000) {
            this.error_msg_text ="Too heavy to process. Bathymetry is too deep (" + max_bty.toFixed(0) + "m)" 
            return true
          }
          else if (max_f <= 1000 && dist > 20000) {
            this.error_msg_text ="Too heavy to process. Please try a distance under 20000m (current: " + dist.toFixed(0) + "m)"
            return true
          }
          else if (max_f <= 1000 && max_bty > 3000) {
            this.error_msg_text ="Too heavy to process. Bathymetry is too deep (" + max_bty.toFixed(0) + "m)" 
            return true
          }
          else {
            return false
          }
        }
      }
      else {
        return false
      }
    },
    getBathymetry: function () {
      let is_valid = true
      for (let item in this.form_validator) {
        //console.log(item, this.form_validator[item])
        if (this.form_validator[item] == false) is_valid = false 
      }

      if(is_valid) {      
        this.bty = [];
        let url =
          "https://rest.emodnet-bathymetry.eu/depth_profile?geom=LINESTRING(" +
          this.pos_emitter.x +
          " " +
          this.pos_emitter.y +
          "," +
          this.pos_receiver.x +
          " " +
          this.pos_receiver.y +
          ")";
        
        getBathymetry(url).then((res) => {
          res.data.forEach((position) => {
            if (position == null) {
              this.bty.push(0);
            } else {
              this.bty.push(position);
            }
          });
        })
      }    
    },
    calculateMeans: function () {
      if(this.sea_properties.ssp){
        let total_bty = 0;
        let total_ssp = 0;
        for(let i = 0; i < this.sea_properties.ssp.length; i++) {
            //total_bty += this.sea_properties.ssp[i][0]
            total_ssp += this.sea_properties.ssp[i][1]
        }
        this.mean_ssp = total_ssp / this.sea_properties.ssp.length;
        for(let i = 0; i < this.bathymetry.length; i++) {
            total_bty += -this.bathymetry[i]
        }
        this.mean_bty = total_bty / this.bathymetry.length
      }
      
    }
  }
}
</script>
<style lang="scss" scoped>
span {
  color: $card-header-text;
}
.overview-row-title {
  font-weight: 600;
  color: #cccccc;
}
.overview-param-title {
  font-size: 1rem;
  color: #393939;
  font-weight: 600;
}
.td-border-right {
  border-right: 1px solid rgba(0,0,0,0.1);
}
td {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.tr-border-bottom {
  border-bottom: 1px solid rgba(0,0,0,0.2);
}
</style>