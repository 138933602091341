<template>
  <div class="graph-3-container">
    <div class="graph-3-card-container">
      <div class="card-header-1 header-thin header-graph-3">
        <div>Frequencies on selected point</div>
        <div style="flex: 1;"></div>
        <div class="graph-3-close" @click="closeGraph3">X</div>
      </div>
      <div class="card-body-1">
        <div class="row h-100">
          <div class="col-sm-12">       
              <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'resultsDisplay3',
  components: {
    Plotly
  },
  data() {
    return {
      values_PL: [],
      data: [
        {
          x: [],
          y: [],
          mode: 'lines',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 2
          },
          hovertemplate: 'PL: %{y:.2f}<extra></extra>',
        }
      ],
      layout:{
        yaxis: {
          ticks: 'outside',
          autorange: 'reversed',
          title: {
            text: 'PL [dB re 1 µPa]'
          }
        },
        xaxis: {
          ticks: 'outside',
          title: {
            text: 'Frequency [Hz]'
          },
          type: 'log',
          autorange: true,
          showexponent: 'all',
          exponentformat: 'power'
        },
        plot_bgcolor:"#052030",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        height: 340,
        margin: {
          t: 10,
          r: 50
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      status: 'ui/status',
      transect_1: 'urn/transect_1',
      transect_2: 'urn/transect_2',
      selected_f: 'urn/selected_f'
    }),
    selected_point_x: {
      set (val) { this.$store.dispatch('urn/change_selected_point_x', val); },
      get () { return this.$store.state.urn.selected_point.x }
    },
    selected_point_y: {
      set (val) { this.$store.dispatch('urn/change_selected_point_y', val); },
      get () { return this.$store.state.urn.selected_point.y }
    },
  },
  mounted () {  
    this.drawGraph()
  },
  methods: {
    drawGraph: function () {
      // Create array with PL values to draw
      for(let i=0; i < this.transect_2.f.length; i++){
        if(this.selected_point_y){
          this.values_PL.push(this.transect_1.TL[this.selected_point_y][this.selected_point_x][i])
        }
        else {
          this.values_PL.push(this.transect_2.TL[this.selected_point_x][i])
        }     
      }
      // Assign values to graph X & Y arrays
      this.data[0].x = this.transect_2.f
      this.data[0].y = this.values_PL
    },
    closeGraph3: function () {
      this.selected_point_y = null
      this.selected_point_x = null
    }  
  }
}
</script>
<style lang="scss" scoped>
.graph-3-container {
  pointer-events: all;
  position: absolute;
  top: 38px;
  bottom: 40px;
  left: 0;
  width: calc(100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.9);
  margin-left: 15px;
  border-radius: 5px;
  z-index: 20;
}
.graph-3-card-container {
  width: 90%;
  border: 1px solid lightgray;
  border-radius: 7px;
  box-shadow: 0px 2px 3px 3px rgba(0,0,0,0.2);
}
.header-graph-3 {
  display: flex;
  flex-direction: row;
}
.graph-3-close {
  cursor: pointer;
}
</style>