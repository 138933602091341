<template>
    <div class="modal fade  bd-example-modal-lg" id="history" tabindex="-1" role="dialog" aria-labelledby="historyLabel" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <table class="table table-borderless  table-striped w-100">
                        <thead>
                            <tr>
                                <th scope="col">Simulation name</th>
                                <th scope="col">Model</th>
                                <th scope="col">Date</th>
                                <th scope="col">Download CSV</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr   
                                v-for="item in job_list"
                                v-bind:key="item.id"
                            >
                                <td>  
                                    <a @click="loadJob(item.id)" class="link-1" data-dismiss="modal">
                                        {{ item.job_name }}
                                    </a>
                                </td>
                                <td>
                                    {{ modelCommonName(item.model) }}
                                </td>
                                <td>
                                    {{ formatDate(item.creation_date) }}
                                </td>
                                <td class="text-center pl-0 pr-3">  
                                    <button type="button" @click="downloadCSV(item.id)" class="btn btn-outline-primary ">CSV</button>
                                </td>
                                <td>
                                    <button type="button" @click="deleteJob(item.id)" class="btn btn-danger ">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row p-2">
                        <div class="col-sm-12 text-right">
                            <a type="button" class="link-1" data-dismiss="modal">Close</a>
                        </div>
                    </div>
                </div>          
            </div>
        </div>
    </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Services
import { postData,downloadData } from '@/services/api'
import { modelName } from '@/services/helper'

export default {
  name: 'history',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      job_list: 'urn/job_list', 
      user_id: 'user/user_id', 
    })
  },
  mounted () { 

  },
  created () {
      this.$store.dispatch('urn/get_job_list')
  },
  methods: {
    loadJob: function (job_id) {
        this.$store.dispatch("ui/change_status", 3)
        this.$store.dispatch("urn/get_job", job_id)
    },
    deleteJob: function (job_id) {
        const formData = new FormData()
        formData.append("job_id", job_id)
        postData("noisemodels/delete/", formData).then(res => {
            if (res.status == 200) {
                this.$noty.success("Simulation deleted")
                this.$store.dispatch('urn/get_job_list')
            }
            else {
                this.$noty.error("Simulation could not be deleted")
            }
        }).catch(error => {
            this.$noty.error("Simulation could not be deleted")
        });
    },
    downloadCSV: function(job_id){
        const formData = new FormData()
        formData.append("job_id", job_id)
        formData.append("user_id", this.user_id)
        downloadData("noisemodels/getjobCSV/", formData).then(res => {
            if (res.status == 200) {
                let blob = new Blob([res.data], {type: 'application/zip'})
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'transect_1_'+ job_id +'.zip'
                link.click()
            }
            else {
                this.$noty.error("Error downloading the CSV")
            }
        }).catch(error => {
            this.$noty.error("Error downloading the CSV")
        });
    },
    formatDate: function (date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },
    modelCommonName: function (model) {
        return modelName(model)
    }
  }
};
</script>

<style lang = "scss" scoped>
.modal-dialog {
  max-width: 850px;
}
</style>
