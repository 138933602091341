<template>
  <div>
    <div class="card-body-2">
        <div style="flex: 1;"></div>
        <div class="card-body-2-data">
          <span class="card-body-2-data-text card-header-text">Long: &nbsp; &nbsp; &nbsp;</span>
          <span class="card-body-2-data-text"> {{ pos_receiver.x }}</span>
        </div>
        <div class="card-body-2-data">
          <span class="card-body-2-data-text card-header-text">Lat: &nbsp; &nbsp; &nbsp;</span>
          <span class="card-body-2-data-text"> {{ pos_receiver.y }}</span>
        </div>
        <div class="card-body-2-buttons-container">
          <span class="button-standard" v-on:click="accept">Accept</span>       
          <span class="button-standard" v-on:click="cancel">Cancel</span> 
        </div>      
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

export default {
  name: 'input6MapSource',
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      pos_receiver: 'urn/pos_receiver'
    })
  },
  data() {
    return {
      temp_long: null,
      temp_lat: null,
    }
  },
  created () {

  },
  mounted () {
    this.temp_long = this.pos_receiver.x
    this.temp_lat = this.pos_receiver.y
  },
  methods: {
    accept: function () {
      this.temp_long = null
      this.temp_lat = null
      this.$store.dispatch('ui/change_current_interface', 2)
    },
    cancel: function () {
      this.$store.dispatch('urn/change_pos_receiver_x', this.temp_long)
      this.$store.dispatch('urn/change_pos_receiver_y', this.temp_lat)
      this.$store.dispatch('ui/change_current_interface', 2)
    }
  }
}
</script>