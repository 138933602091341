<template>
  <form @submit.prevent="reset">
    <div class="form-group row">
      <div class="col-md-12">
        <label class="pb-2">Type a new password</label>
        <input
          class="form-control form-password"
          required
          v-model="password"
          :v="$v.password"
          type="password"
          placeholder="Password"
        />
        <div v-if="$v.password.$error ">
            <label v-if="!$v.password.minLength" class=" col-form-label invalid p-0">Password must be at least 8 characters long</label>
            <label v-if="!$v.password.notOnlyNumeric" class=" col-form-label invalid p-0">Password cannot have only numbers</label>
        </div>
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row pb-3">
      <div class="col-md-12">
        <input
          class="form-control form-password"
          required
          v-model="password2"
          type="password"
          :v="$v.password2"
          placeholder="Repeat password"
        />
        <label v-if="$v.password2.$error" class=" col-form-label invalid p-0"
          >Passwords do not match
        </label>
      </div>
      <!-- input-group.// -->
    </div>
    <!-- form-group// -->
    <div class="form-group row">
      <div class="col-md-8 mx-auto text-center">
        <button type="submit" class="btn btn-outline-secondary">
          Send
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { postData } from "./../../services/api";
import {
  required,
  minLength,
  sameAsPassword,
  sameAs,
  not,
  numeric
} from "vuelidate/lib/validators";
import { common  } from "@/services/validators";
export default {
  name: "reset-pass",
  props: {
    token: String
  },
  data() {
    return {
      password: "",
      password2: "",
    };
  },
  mounted() {
  },
  validations: {
    password: {
      required,
      notOnlyNumeric:not(numeric),
      minLength: minLength(8),
    },
    password2: {required, sameAsPassword: sameAs("password") }
  },
  methods: {
    reset: function() {
      this.$v.password.$touch();
      this.$v.password2.$touch();
      if (this.$v.password.$error || this.$v.password2.$error) return;

      var form = {
        password: this.password,
        token: this.token
      };
      postData("auth-jwt/password-reset/confirm/", form).then(res => {
        if (res.status == 200 && res.data.status == "OK") {
          this.$noty.success("Password updated successfully");
          this.$parent.viewComponent = 'login' 
        } else {
          var errors = res.data.password
          if (errors){
            this.password = ''
            this.password2 = ''
          }else{
            this.$noty.error("Password could not be reset");
            this.password = ''
            this.password2 = ''
          }

        }
      }).catch(error => {
        this.$noty.error("Password could not be reset");
      });
    }
  }
};
</script>

<style lang= "scss" scoped>

  $border-color: #32AC91;

</style>
