<template>
    <div>
        <form @submit.prevent="setUser">
            <h4 v-if="user_id">Edit Profile</h4>
            <h4 v-else>New User</h4>
            <div class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Username</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="form.username" placeholder="Username">
                    <label v-bind:class="{ acultar: !$v.form.username.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field may not be blank.
                    </label>
                </div>
            </div>
            <div v-if="!user_id" class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9">
                    <input
                    class="form-control"
                    v-model="password"
                    :v="$v.password"
                    type="password"
                    placeholder="Password"
                    />
                    <div v-bind:class="{ acultar: !$v.password.$error }" >
                        <label v-if = "!$v.password.minLength"  class=" col-form-label invalid p-0">It must contain at least 8 characters.</label>
                        <label v-bind:class="{ acultar: $v.password.notOnlyNumeric }" class=" col-form-label invalid p-0 ml-2">Don't just use numbers.</label>
                    </div>
                </div>
            </div>
            <div v-if="!user_id"  class="form-group row  mb-1">
                <label class="col-sm-3 col-form-label">Repeat password</label>
                <div class="col-md-9">
                    <input
                    class="form-control form-password"
                    v-model="password2"
                    type="password"
                    :v="$v.password2"
                    placeholder="Repeat password"
                    />
                    <label v-bind:class="{ acultar: !$v.password2.$error }" class=" col-form-label invalid p-0">
                        Password fields didn't match.
                    </label>
                </div>
            </div>
            <!-- <div class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">First name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="form.first_name" placeholder="First name">
                    <label v-bind:class="{ acultar: !$v.form.first_name.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field may not be blank.
                    </label>
                </div>
            </div> -->
            <!-- <div class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Last name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="form.last_name" placeholder="Last name">
                    <label v-bind:class="{ acultar: !$v.form.last_name.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field may not be blank.
                    </label>
                </div>
            </div> -->
            <div class="form-group row mb-1">
                <label  class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                    <input type="email" class="form-control" v-model="form.email" placeholder="Email">
                    <label v-bind:class="{ acultar: !$v.form.email.$error }" class="col-form-label invalid col-sm-12 p-0">
                        This field may not be blank.
                    </label>
                </div>
            </div>
            <div  v-if="user_id && !personalProfile" class="form-group row">
                <div class="col-sm-3">Active</div>
                <div class="col-sm-9">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="form.is_active" >
                        <label class="form-check-label" >
                            Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="(is_admin == true || is_admin == 'true')  && !personalProfile" class="form-group row">
                <div class="col-sm-3">Staff status</div>
                <div class="col-sm-9">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="form.is_staff" >
                        <label class="form-check-label">
                            Designates whether the user can log into this admin site.
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="(is_admin == true || is_admin == 'true') && !personalProfile" class="form-group row ">
                <div class="col-sm-3">Superuser status</div>
                <div class="col-sm-9">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox"  v-model="form.is_superuser" >
                        <label class="form-check-label">
                            Designates that this user has all permissions without explicitly assigning them.
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row ">
                <div class="col-sm-4 text-left">
                    <button type="button" v-if="user_id && (is_admin == true || is_admin == 'true') && !personalProfile" @click="deleteUser()" class="btn btn-danger ">Delete</button>
                </div>
                <div class="col-sm-8 text-right">
                     <a v-if="!personalProfile" @click="$parent.showUserList = true" class="link-1" > User List </a>
                    <button type="submit" class="btn btn-secondary ml-3">Update</button>
                </div>
            </div>
        </form>
    
        <form v-if="personalProfile" @submit.prevent="changePassword">
            <h4>Change Password</h4>
            <div  class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Old password</label>
                <div class="col-sm-9">
                    <input
                    class="form-control"
                    v-model="old_password"
                    :v="$v.old_password"
                    type="password"
                    placeholder="Old password"
                    />
                </div>
                <label class="acultar col-form-label invalid p-0">It </label>
            </div>
            <div  class="form-group row mb-1">
                <label class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9">
                    <input
                    class="form-control"
                    v-model="password"
                    :v="$v.password"
                    type="password"
                    placeholder="Password"
                    />
                    <div v-bind:class="{ acultar: !$v.password.$error }" >
                        <label v-if = "!$v.password.minLength"  class=" col-form-label invalid p-0">It must contain at least 8 characters.</label>
                        <label v-bind:class="{ acultar: $v.password.notOnlyNumeric }" class=" col-form-label invalid p-0 ml-2">Don't just use numbers.</label>
                    </div>
                </div>
            </div>
            <div class="form-group row  mb-1">
                <label class="col-sm-3 col-form-label">Repeat password</label>
                <div class="col-md-9">
                    <input
                    class="form-control"
                    v-model="password2"
                    type="password"
                    :v="$v.password2"
                    placeholder="Repeat password"
                    />
                    <label v-bind:class="{ acultar: !$v.password2.$error || password2 == '' }" class=" col-form-label invalid p-0">
                        Password fields didn't match.
                    </label>
                </div>
            </div>
            <div class="form-group row ">
                <div class="col-sm-12 text-right">
                    <button type="submit" class="btn btn-secondary ml-3">Update</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import { postData, putData, deleteData } from "@/services/api";
import { mapGetters } from 'vuex'

import {
  required,
  minLength,
  sameAs,
  not,
  numeric
} from "vuelidate/lib/validators";

export default {
  name: 'userEdit',
  props: {
    user: Object,
    personalProfile: Boolean 
  },
  data() {
    return {
        password: '',
        password2: '',
        old_password: '',
        user_id: '',
         form: {
            email: '',
            username: '',
            // first_name: '',
            // last_name: '',
            is_active: '',
            is_staff: false,
            is_superuser: false,
         }
    };
  },
  validations: {
    form: {
      email: { required },
      username: { required },
    //   first_name: { required },
    //   last_name: { required },
    },
    old_password: {required},
    password: {required,notOnlyNumeric:not(numeric),minLength: minLength(8)},
    password2: {required, sameAsPassword: sameAs("password") }
  },
  computed: { 
    ...mapGetters("user",{
      is_admin: 'is_admin',
      id: 'user_id',
      username: 'username',
    //   first_name: 'first_name',
    //   last_name: 'last_name',
      email: 'email',
    }) 
  },
  created () {
    if(this.personalProfile == true){
        this.user_id = this.id 
        this.form['id'] = this.user_id
        this.form['username'] = this.username
        // this.form['first_name'] = this.first_name
        // this.form['last_name'] = this.last_name
        this.form['email'] = this.email
        
    }else if (this.user.id){
        this.user_id = this.user.id 
        this.form = this.user
    }
  },
  
  methods: {
      setUser(){
        if (!this.user_id) {
            //new user
            this.$v.password.$touch();
            this.$v.password2.$touch();
            this.$v.form.$touch();
            if (this.$v.password.$error || this.$v.password2.$error || this.$v.form.$pending || this.$v.form.$error) return;

            this.form['password'] = this.password
            this.form['password2'] = this.password2
      
            postData("auth-jwt/register/", this.form)
            .then(res => {
                if(res.status < 400){
                    this.$noty.success("Successful update");
                    this.form = {}
                    this.password = ''
                    this.password2 = ''
                    this.$parent.showUserList = true
                    this.$parent.getUser()
                }else{
                    this.$noty.error("Oops, something went wrong!");
                } 
            })
            .catch(error => {
                this.$noty.error("Oops, something went wrong!");
            });

        }else{
            //Update
            this.$v.form.$touch();
            if (this.$v.form.$pending || this.$v.form.$error) return; 
            if(this.personalProfile == true){
                delete this.form.is_superuser
                delete this.form.is_staff
                delete this.form.is_active
            }
            putData("auth-jwt/update-profile/",  this.user_id, this.form)
            .then(res => {
                if(res.status < 400){
                    this.$noty.success("Successful update");
                    if(this.personalProfile == false){
                        this.form = {}
                        this.$parent.showUserList = true
                        this.$parent.getUser()
                    }

                }else{
                    this.$noty.error("Oops, something went wrong!");
                } 
            })
            .catch(error => {
                this.$noty.error("Oops, something went wrong!");
            });
        }
      },
      deleteUser(){
        var destroy = confirm("Are you sure you want to delete the user '"+this.form['username']+"'?")
        if(destroy){
            deleteData("auth-jwt/delete-profile/", this.user_id)
                .then(res => {
                    if(res.status < 400){
                        this.$noty.success("Successful update");
                        this.$parent.showUserList = true
                        this.$parent.getUser()
                    }else{
                        this.$noty.error("Oops, something went wrong!");
                    } 
                })
                .catch(error => {
                    this.$noty.error("Oops, something went wrong!");
                });
        }

      },
      changePassword(){
        this.$v.password.$touch();
        this.$v.password2.$touch();
        this.$v.old_password.$touch();
        if (this.$v.password.$error || this.$v.password2.$error || this.$v.old_password.$error ) return;

        var data={
            'password': this.password,
            'password2': this.password2,
            'old_password': this.old_password,
        }

        putData("auth-jwt/change-password/",  this.user_id, data)
            .then(res => {
                if(res.status < 400){
                    this.$noty.success("Successful update");
                     this.password = ''
                     this.password2 = ''
                     this.old_password = ''
                }else{
                    this.$noty.error("Oops, something went wrong!");
                } 
                })
            .catch(error => {
                this.$noty.error("Oops, something went wrong!");
            });
      }
  }
};
</script>

<style lang = "scss" scoped>

</style>
