<template>
  <div>
    <div class="card-header-1">SPECTRUM</div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(2)"> &lt; </div>
        <div class="col-sm-10">

          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>

          <div class="row h-75 form-group">
            <div class="col-sm-4 my-auto">
              <div class="input-label">Initial frequency [Hz]</div>
              <input class="general-input form-control" placeholder="Initial frequency" :v="$v.f_ini" type="text" v-model="f_ini">
              <!--<div v-if="!$v.f_ini.required" class="col-form-label pos-absolute invalid col-sm-12">Initial frequency is required</div>-->
              <div v-if="!$v.f_ini.decimal" class="col-form-label pos-absolute invalid col-sm-12">Frequency must be a number</div>
              <div v-else-if="!$v.f_ini.minValue" class="col-form-label pos-absolute invalid col-sm-12">Frequency must be higher than {{ min_allowed_freq }}Hz</div>
            </div>
            <div class="col-sm-4 my-auto">
              <div class="input-label">Final frequency [Hz]</div>
              <input class="general-input form-control"  placeholder="Final frequency" type="text" :v="$v.f_end" v-model="f_end">
              <!--<div v-if="!$v.f_end.required" class="col-form-label pos-absolute invalid col-sm-12">Final frequency is required</div>-->
              <div v-if="!$v.f_end.decimal" class="col-form-label pos-absolute invalid col-sm-12">Frequency must be a number</div>
              <div v-else-if="!$v.f_end.minValue" class="col-form-label pos-absolute invalid col-sm-12">Frequency must be higher than initial frequency</div>
              <div v-else-if="!$v.f_end.maxValue" class="col-form-label pos-absolute invalid col-sm-12">Frequency must be under {{ max_allowed_freq }}KHz</div>
            </div>
            <div class="col-sm-4 my-auto">
              <div class="input-label">Frequency step</div>
              <select v-model="f_step" class="select-input">
                  <option value="1">Octaves</option>
                  <option value="2">1/3 Octaves</option>
                  <option value="3">Decidecades</option>
              </select>
            </div>        
          </div>
          
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(4)"> &gt; </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Components and services
import navigation from '@/components/URN/navigation.vue'
import {required, decimal, maxValue, minValue} from 'vuelidate/lib/validators'
import { toastIfNaN } from '@/services/helper'

export default {
  name: 'input13Freq',
  components: {
    navigation
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      max_allowed_freq: 'urn/max_allowed_freq',
      min_allowed_freq: 'urn/min_allowed_freq'
    }),
    f_ini: {
          set (val) { 
            toastIfNaN(val, "Initial Frecuency must be a number");
            this.$store.dispatch('urn/change_f_ini', val); },
          get () { return this.$store.state.urn.f_ini }
    },
    f_end: {
          set (val) { 
            toastIfNaN(val, "Final Frecuency must be a number");
            this.$store.dispatch('urn/change_f_end', val); },
          get () { return this.$store.state.urn.f_end }
    },
    f_step: {
          set (val) { 
            this.$store.dispatch('urn/change_f_step', val); },
          get () { return this.$store.state.urn.f_step }
    },
  },
  validations: {
    f_ini: {
      required, 
      decimal,
      minValue (value) {
          return minValue(parseFloat(this.min_allowed_freq))(value)
      },
    },
    f_end: {
        required, 
        decimal,
        minValue (value) {
          return minValue(parseFloat(this.f_ini) + 1)(value)
        },
        maxValue (value) {
          return maxValue(parseFloat(this.max_allowed_freq))(value)
        }
    }
  },
  data() {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    }
  }
}
</script>