<template>
  <div id="app">
    <loader></loader>
    <propagationHeader></propagationHeader>
      <router-view></router-view>
    <propagationFooter></propagationFooter>
  </div>
</template>


<script>
import propagationHeader from '@/components/header'
import propagationFooter from '@/components/footer'
import loader from '@/components/loader'


export default {
  name: 'App',
  components: {
    propagationFooter,
    propagationHeader,
    loader,
    // confirmModal,
    // notificationModal
  },
   data() {
    return {

    }
  },
  computed: { 
  },
  mounted () {

  },
  methods: { 
  }
}
</script>

<style lang="scss">
#app {
  width:100%; 
  height:100%; 
  position:fixed; 
  left:0;
  top:0;
  overflow:hidden;
}

</style>
