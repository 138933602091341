import axios from 'axios'

const getlocal = (key) => {
return localStorage.getItem(key);
};

const getArraylocal = (key) => {
if (localStorage.getItem(key))
    return localStorage.getItem(key).split(',');
};

const setlocal = (key, value) => {
    localStorage.setItem(key, value)
}

const deleteAll = () => {
    localStorage.clear();
    delete axios.defaults.headers.common['Authorization']
};




const setlocalToken = (key, value) => {
    localStorage.setItem(key, value)
    axios.defaults.headers.common['Authorization'] = "Bearer  " + value
}



const deletelocalToken = (key) => {
    localStorage.removeItem(key)
    delete axios.defaults.headers.common['Authorization']
};




export {
    getlocal,
    getArraylocal,
    setlocalToken,
    deletelocalToken,
    setlocal,
    deleteAll
}