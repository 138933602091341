<template>
  <div>
    <!--<Plotly :data="data" :layout="layout" :display-mode-bar="true" :scrollZoom="true" :displaylogo="false"></Plotly>-->
    <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" id="plotly" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'resultsDisplay',
  components: {
    Plotly
  },
  data () {
    return {
      max_PL: 100,
      min_PL: 30,
      data: [
        {
          z: [],
          type: 'heatmap',
          colorbar: {
            title: 'PL [dB re 1 µPa]'
          },
          hovertemplate: 'PL: %{z:.2f}<extra></extra>',
        },
        {
          x: [],
          y: [],
          mode: 'lines',
          hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 2
          }
        }
      ],
      layout:{
        //title: "My graph",
        yaxis: {
          ticks: 'outside',
          autorange: 'reversed',
          title: {
            text: 'Depth [m]'
          }
        },
        xaxis: {
          ticks: 'outside',
          title: {
            text: 'Radial distance [m]'
          }
        },
        plot_bgcolor:"#052030",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        //autosize: true,
        height: 200,
        margin: {
          t: 10,
          r: 160,
          b: 40
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      }
    }  
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      transect_1: 'urn/transect_1',
      bathymetry: 'urn/bathymetry',
      pos_emitter: 'urn/pos_emitter',
      pos_receiver: 'urn/pos_receiver',
      selected_f: 'urn/selected_f',
    }),
    selected_point_x: {
      set (val) { this.$store.dispatch('urn/change_selected_point_x', val); },
      get () { return this.$store.state.urn.selected_point.x }
    },
    selected_point_y: {
      set (val) { this.$store.dispatch('urn/change_selected_point_y', val); },
      get () { return this.$store.state.urn.selected_point.y }
    },
  },
  props: {
    height: Number
  },
  created() {
    this.layout.height = this.height
  },
  mounted () {
    // Set max PL
    this.max_PL = 20 * Math.log10(this.transect_1.rR[this.transect_1.rR.length-1])

    // Draw Graph and transect
    this.drawGraph()
    this.drawTransect()

    // Create listener to handle click event (To display freqs graph)
    let globalThis = this
    document.getElementById('plotly').on('plotly_click', function(data){
      globalThis.setSelectedPoint(data)
    })
  },
  watch: {
    selected_f: function () {
      // When a frequency is selected, graph is re-drawn
      this.drawGraph()
    },
  },
  methods: {
    drawGraph: function () {
      this.data[0].x = this.transect_1.rR
      this.data[0].y = this.transect_1.zR
      this.data[0].z = []
      for(let i = 0; i < this.transect_1.zR.length; i++){
          this.data[0].z.push([])
          for(let j = 0; j < this.transect_1.rR.length; j++){
              this.data[0].z[this.data[0].z.length-1].push(this.transect_1.TL[i][j][this.selected_f])     
          }
      }
      //this.data[0].type = 'heatmap'
      //this.data[0].name = 'PL'
      this.data[0].zmin = this.min_PL
      this.data[0].zmax = this.max_PL
      this.data[0].colorscale = 'Portland'
      this.data[0].reversescale = true
      //this.data[0].zsmooth = 'fast'
    },
    drawTransect: function () {
      this.data[1].x = [0, this.transect_1.rR[this.transect_1.rR.length-1]]
      this.data[1].y = [this.pos_emitter.z, this.pos_receiver.z]
      this.data[1].reversescale = true
      this.data[1].name = 'Transect'
    },
    setSelectedPoint: function (data) {
      this.selected_point_y = this.transect_1.zR.indexOf(data.points[0].y)
      this.selected_point_x = this.transect_1.rR.indexOf(data.points[0].x)
    },  
  }
}
</script>