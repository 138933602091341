<template>
  <div>
    <div class="card-header-1">SOURCE POSITION</div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow"> <!--&lt;--> </div>
        <div class="col-sm-10">

          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>

          <div class="row h-75 form-group">
            <div class=" col-sm-4 my-auto">
              <div class="input-label" >Longitude</div>
              <input class="general-input form-control" :v="$v.x.decimal" placeholder="Longitude" type="text" v-model="x" @blur="getBathymetry">
              <!--<span v-if="!$v.x.required" class="col-form-label pos-absolute invalid col-sm-12">Longitude is required</span>-->
              <!-- <div v-if="!$v.x.decimal" class="col-form-label pos-absolute invalid col-sm-12">Longitude must be a number</div> -->
            </div>
            <div class="col-sm-4 my-auto">
              <div class="input-label">Latitude</div>
              <input class="general-input form-control" :v="$v.y" placeholder="Latitude" type="text" v-model="y" @blur="getBathymetry">
              <!--<div v-if="!$v.y.required" class="col-form-label pos-absolute invalid col-sm-12">Latitude is required</div>-->
              <!-- <div v-if="!$v.y.decimal" class="col-form-label pos-absolute invalid col-sm-12">Latitude must be a number</div> -->

            </div>
            <div class="col-sm-4 my-auto">
              <div class="input-label">Depth [m]</div>
              <input class="general-input form-control" :v="$v.z" placeholder="Depth" type="text"  v-model="z">
              <!--<div v-if="!$v.z.required" class="col-form-label pos-absolute invalid col-sm-12">Depth is required</div>-->
              <!-- <div v-if="!$v.z.decimal" class="col-form-label pos-absolute invalid col-sm-12">Depth must be a number</div> -->
              <div v-if="max_depth == null && $v.z.required && $v.z.decimal" class="col-form-label pos-absolute info col-sm-12">Set a valid point (long / lat) first</div>
              <div v-else-if="!$v.z.maxValue && $v.z.required && $v.z.decimal" class="col-form-label pos-absolute invalid col-sm-12">Max depth in this point is {{ max_depth }} m</div>         
              <div v-else-if="max_depth != null && $v.z.decimal" class="col-form-label pos-absolute info col-sm-12">Max depth in this point is {{ max_depth }} m</div>
            </div>        
          </div>

          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6 text-center">
              <div class="button-standard" v-on:click="navigate(6)">Select on map</div>
            </div>
            <div class="col-sm-3"></div>
          </div>
          
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(2)"> &gt; </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Components and services
import navigation from '@/components/URN/navigation.vue'
import { required, decimal, numeric, maxValue } from 'vuelidate/lib/validators'
import { getBathymetry } from '@/services/api'
import { toastIfNaN } from '@/services/helper'
// import Vue from 'vue'
// import VueToast from 'vue-toast-notification';

export default {
  name: 'input1Source',
  components: {
    navigation
  },

  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      pos_emitter: 'urn/pos_emitter',
      //validations: 'urn/validations'
    }),
    x: {
          set (val) { 
            // LLamar a la funcion checkNaN para sacar el toastr o no.
              toastIfNaN(val, "Longitude must be a number");
              this.$store.dispatch('urn/change_pos_emitter_x', val); 
              }, 
          get () { return this.$store.state.urn.pos_emitter.x }
    },
    y: {
          set (val) { 
            toastIfNaN(val, "Latitude must be a number");
            this.$store.dispatch('urn/change_pos_emitter_y', val);},
          get () { return this.$store.state.urn.pos_emitter.y }
    },
    z: {
          set (val) { 
            toastIfNaN(val, "Depht must be a number");
            this.$store.dispatch('urn/change_pos_emitter_z', val); },
          get () { return this.$store.state.urn.pos_emitter.z}
    },
  },
  data() {
    return {
      max_depth: null   
    }
  },
  validations: {
      x: {required, decimal},
      y: {required, decimal},
      z: {
          required, 
          decimal,
          maxValue (value) {
            return maxValue(this.max_depth)(value)
          }
      }
  }, 
  created () {
    this.getBathymetry()
  },
  mounted () {
  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    getBathymetry: function () {
      if(this.$v.x.required && this.$v.x.decimal && this.$v.y.required && this.$v.y.decimal){
        // Get bathymetry from Emodnet API
        let url = 'https://rest.emodnet-bathymetry.eu/depth_sample?geom=POINT(' +
                  this.pos_emitter.x + ' ' + this.pos_emitter.y + ')'          
        getBathymetry(url)
        .then(res => {
          if (res.data.avg != undefined && res.data.avg <= 0) {
            this.max_depth = -res.data.avg
          }
          else {
            this.max_depth = null
          }
        })
      }
      else {
        this.max_depth = null
      }
    },
  }
}
</script>