<template>
  <div class="container-fluid p-0 home-bg">
    <div v-if="showModal" class='modal-confirm'>
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click="closeModal" style="margin-right: 10px; font-size: 1.2rem;">&times;</span>
                  </button>
                </div>
                <div class="modal-body" style="display: flex; justify-content: center; margin-top: 30px;">
                  <p>Current simulation is not saved, proceed anyway?</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                  <button type="button" class="btn btn-primary"  @click="newJob">Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div> 
    <div class="button-new-job" v-on:click="checkIfWorkIsSaved" v-if="current_interface !=6 && current_interface != 7">New simulation</div>
    <div id="map" class="map-container" v-on:click="getCoords"></div>
    <div class="pt-2 row h-100 interface-overlay">
      <div class="col-sm-2"></div>
      <div v-if="current_interface < 8" class="col-sm-8 my-auto">
        <input-1-source v-if="current_interface == 1"></input-1-source>
        <input-2-destination v-if="current_interface == 2"></input-2-destination>
        <input-3-freq v-if="current_interface == 3"></input-3-freq>
        <input-4-model v-if="current_interface == 4"></input-4-model>
        <input-5-overview v-if="current_interface == 5"></input-5-overview>
        <input-6-map-source v-if="current_interface == 6"></input-6-map-source>
        <input-7-map-receiver v-if="current_interface == 7"></input-7-map-receiver>     
      </div>
      <div v-else class="col-sm-8">
        <results-container v-if="current_interface == 8"></results-container>
        <results-display-3 v-if="selected_point_x"></results-display-3>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</template>
 
<script>
// Leaflet
import "leaflet/dist/leaflet.css"
import L from "leaflet"

// Vuex
import { mapGetters } from "vuex";

// Components and services
import { postData, getData } from '@/services/api'
import input1Source from '@/components/URN/input1Source.vue'
import input2Destination from '@/components/URN/input2Destination.vue'
import input3Freq from '@/components/URN/input3Freq.vue'
import input4Model from '@/components/URN/input4Model.vue'
import input5Overview from '@/components/URN/input5Overview.vue'
import input6MapSource from '@/components/URN/input6MapSource.vue'
import input7MapReceiver from '@/components/URN/input7MapReceiver.vue'
import resultsContainer from '@/components/URN/resultsContainer.vue'
import ResultsDisplay3 from '@/components/URN/resultsDisplay3.vue'

export default {
  name: 'URN',
  components: {
    input1Source,
    input2Destination,
    input3Freq,
    input4Model,
    input5Overview,
    input6MapSource,
    input7MapReceiver,
    resultsContainer,
    ResultsDisplay3
  },
  computed: {
    ...mapGetters({
      user_id: 'user/user_id',
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      pos_emitter: 'urn/pos_emitter',
      pos_receiver: 'urn/pos_receiver',
      selected_point_x: 'urn/selected_point_x',
      last_pos_type_modified:'urn/last_pos_type_modified',
      job_stored: 'urn/job_stored',
      showModal: 'urn/showModal'
    })
  },
  data() {
    return {
      map: null,
      icon_emitter: {},
      icon_receiver: {},
      marker_emitter: null,
      marker_receiver: null,
      marker_transect: null,
      socket: null,
    }
  },
  created () {
    const server_path = process.env.VUE_APP_API_WS_S + '://' + process.env.VUE_APP_API_HOST + ':' + process.env.VUE_APP_API_PORT + '/ws/updates/'
    this.socket = new WebSocket(server_path)
    let globalThis = this
    this.socket.addEventListener('open', () => {
      console.log('Conected to websocket channel');
      // this.socket.send(JSON.stringify({
      //   'user_id': this.user_id,
      //   'active': true
      // }));
    })

    // window.addEventListener('beforeunload', function (e) {
    //     globalThis.socket.send(JSON.stringify({
    //     'user_id': user_id,
    //     'active': false
    //   }));
    // });
   
    this.socket.onmessage = function (event) {
      const data = JSON.parse(event.data)
      // console.log(data,globalThis.user_id)
      if(data.user_id == globalThis.user_id) {
        if(data.job_type == "tsi") {
          globalThis.getResults(data.job_id, data.job_type)
          // console.log(data)
        }else if(data.job_type == "error"){
          globalThis.$store.dispatch("ui/change_current_interface", 5, { root: true });
          globalThis.$store.dispatch("ui/change_status", 1, { root: true });
          globalThis.$toast.open({
              message: "Something went wrong. Process stopped.",
              type: "error",
              position: "top",
              pauseOnHover: true,
              duration: 6000,
            });
        }
      }
      
    }
    //this.deleteJobs()
 
  },
  mounted () {
    this.setBaseMap()
    this.loadIcons()
  },
  watch: {
    'pos_emitter.x': function () {
      this.updateMarker('emitter')
    },
    'pos_emitter.y': function () {
      this.updateMarker('emitter')
    },
    'pos_receiver.x': function () {
      this.updateMarker('receiver')
    },
    'pos_receiver.y': function () {
      this.updateMarker('receiver')
    }
  },
  methods: {
    setBaseMap: function () {
      this.map = L.map("map", {zoomControl: false}).setView([38.959, 12.623], 6);
      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            '&copy; <a href="https://www.ctnaval.com/en/">Centro Tecnológico Naval y del Mar</a>',
        }
      ).addTo(this.map)
      L.control.scale({imperial: false}).addTo(this.map)
    },
    getResults: function (job_id, job_type) {
      let data = [job_id, job_type]
      this.$store.dispatch('urn/get_job', job_id)
    },
    getCoords: function (e) {
      if(this.current_interface == 6){
        let latlng = this.map.mouseEventToLatLng(e);
        //this.$store.dispatch('urn/change_pos_emitter_x', latlng.lng)
        //this.$store.dispatch('urn/change_pos_emitter_y', latlng.lat)
        this.$store.dispatch('urn/change_pos_emitter_from_map', latlng)
      }
      else if(this.current_interface == 7){
        let latlng = this.map.mouseEventToLatLng(e);
        this.$store.dispatch('urn/change_pos_receiver_x', latlng.lng)
        this.$store.dispatch('urn/change_pos_receiver_y', latlng.lat)
      }
    },
    drawLine: function () {
      if(this.marker_transect){
        this.map.removeLayer(this.marker_transect)
      }
      if(this.pos_emitter.x && this.pos_emitter.y && this.pos_receiver.x && this.pos_receiver.y){
        let line = []
        line.push([this.pos_emitter.y, this.pos_emitter.x])
        line.push([this.pos_receiver.y, this.pos_receiver.x])
        this.marker_transect = L.polyline(line, {color: 'rgb(84,186,179)', weight: 3, opacity: 0.9})
        this.marker_transect.addTo(this.map)
      }
    },
    loadIcons: function () {
      let image_emitter = require('@/assets/icons/icon_emitter.gif')
      this.icon_emitter = L.icon({
        iconUrl: image_emitter,
        iconSize:     [100, 100], // size of the icon
        iconAnchor:   [50, 50], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })

      let image_receiver = require('@/assets/icons/icon_receiver.png')
      this.icon_receiver = L.icon({
        iconUrl: image_receiver,
        iconSize:     [12, 12], // size of the icon
        iconAnchor:   [6, 6], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })
    },

    checkIfWorkIsSaved: function () {
      if (this.job_stored == true) {
        this.newJob()
      } else {
        this.$store.dispatch('urn/change_modal_state', true)
      }
    },

    closeModal: function () {
      this.$store.dispatch('urn/change_modal_state', false)
    },

    newJob: function () {
      this.$store.dispatch('ui/change_current_interface', 1)
      this.$store.dispatch('urn/change_job_id', '')
      this.$store.dispatch('urn/change_job_name', null)
      this.$store.dispatch('urn/change_modal_state', false)
    },
    updateMarker: function (point) {
      // Update emmiter marker
      if (point == 'emitter'){
        if (this.marker_emitter){
            this.map.removeLayer(this.marker_emitter)
        }
        if (this.pos_emitter.x && this.pos_emitter.y){
            this.marker_emitter = L.marker([this.pos_emitter.y, this.pos_emitter.x], {icon: this.icon_emitter})
            this.marker_emitter.addTo(this.map)
        }
      }

      // Update receiver marker
      else if (point == 'receiver') {     
        if (this.marker_receiver){
            this.map.removeLayer(this.marker_receiver)
        }
        if (this.pos_receiver.x && this.pos_receiver.y){
            this.marker_receiver = L.marker([this.pos_receiver.y, this.pos_receiver.x], {icon: this.icon_receiver})
            this.marker_receiver.addTo(this.map)
        }
      }
      
      this.drawLine()
    },
    deleteJobs: function () {
      const formData = new FormData()
      postData("noisemodels/delete/", formData)
    },
  }
}
</script>
<style scoped>
  .modal-confirm {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 99999999;
  }
</style>