<template>
   <div class="container-fluid w-100 h-100 p-0 h-0 home-bg">
    <div class="layout-login">
      <div class="card-login">
         <!--<img
              :src="require('@/assets/logo.png')"
              alt="user icon"
            />-->
        <span class="navbar-title" style="font-size: 1.2rem; text-align: center">Underwater Noise Propagation Tool</span>
        <div class="line-separator"><hr /></div>
        <div class="login-body">
          <login v-if="viewComponent == 'login'"></login> 
          <forgottenPass  v-else-if = "viewComponent == 'forgot'"></forgottenPass>
          <resetPass  v-else-if = "viewComponent == 'reset'" v-bind:token = $route.query.token  ></resetPass>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import login from '@/components/user/login.vue'
import forgottenPass from '@/components/user/forgotten_pass.vue'
import resetPass from '@/components/user/reset_pass.vue'
  export default {
    components: {
      login,
      forgottenPass,
      resetPass
    },
     data(){
      return {
        viewComponent: 'login',
        token: ''
      }
    },
    mounted(){
      if(this.$route.query.token) 
        this.viewComponent = 'reset' 
    },
    methods: {

    }
  }
</script>


<style lang= "scss" scoped>

.card-login {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 300px;
    margin-left: auto;
    margin-right: auto; 
    background-color: $card-background;

}

.card-login > img {
    max-width: 200px;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

@supports(object-fit: cover){
    .card-login > img{
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
}

.box{
  width: 300px;
  height: 300px;
  background: #CCC;
  overflow: hidden;
}

.box img{
  width: 100%;
  height: auto;
}


.login-body {
  /* margin-top: 20px; */
}

 @media only screen and (min-width: $mobile-width) {
  .card-login {
    padding: 30px 50px;
    top: 14%;
    width: 350px;
    background-clip: border-box;
    border: 1px solid $input-background;
    border-radius: .65rem;
  }
  .card-login > img {
    max-width: 120px;
  }
 }


</style>