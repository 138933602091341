<template>
    <div class="loader-container">
        <div class="loader" v-bind:class="{ 'loading': status>=2 }">
          <div class="loader-text" v-bind:class="{ 'spinner-loading': status>=2 }">
              <div v-if="status == 3">Loading stored data...</div>
              <div v-else-if="!get_properties">Processing...</div>
              <div v-else>Obtaining sea properties...</div>
              <div class="loader-sub-text" v-if="!get_properties">This process may take several minutes</div>
              <div class="lds-ellipsis" v-bind:class="{ 'spinner-loading': status>=2 }"><div></div><div></div><div></div><div></div></div>
              <div v-if="!get_properties" class="loader-text" v-bind:class="{ 'spinner-loading': status==2 }">
                  <input5Overview v-if="status==2" v-bind:class="{ 'spinner-loading loader-overview': status==2 }"></input5Overview>
              </div>      
          </div>
        </div>
        <div class="loader-wall" v-bind:class="{ 'loader-wall-loading': status==2 }"></div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import input5Overview from '@/components/URN/input5Overview.vue' 

export default {
    name: 'loader',
    components: {
    input5Overview
  },
    computed: {
    ...mapGetters({
      status: 'ui/status',
      get_properties: 'ui/get_properties'
    }),
  },
}
</script>