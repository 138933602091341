import { getData, postData, getBathymetry } from "@/services/api";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Vuelidate from "vuelidate";
import { getDistance } from '@/services/helper'

Vue.use(VueToast, Vuelidate);

const state = () => ({
  models_list: ['cilindrica', 'log19', 'esferica', 'energetico', 'colossus', 'reflexion_superficie', 'reflexion_superficie_lecho', 'bellhop', 'kraken', 'SimplePE'],
  pos_emitter: {
    x: '',
    y: '',
    z: '',
  },
  pos_receiver: {
    x: '',
    y: '',
    z: '',
  },
  cartesian_or_geographic: "cartesian",
  last_pos_type_modified: "",
  pos_receiver_cartesian: {
    x: 0,
    y: 0,
  },
  f_ini: '',
  f_end: '',
  f_step: 1, // 1= Octavas // 2= 1/3 Octavas
  model: "cilindrica",
  bathymetry: null,
  result: {
    transect_1: {
      rR: null,
      zR: null,
      f: null,
      TL: null,
    },
    transect_2: {
      rR: null,
      f: null,
      TL: null,
    },
  },
  selected_f: 0,
  graph_color: {
    min: {
      r: 255,
      g: 211,
      b: 107,
    },
    mid: {
      r: 168,
      g: 14,
      b: 84,
    },
    max: {
      r: 11,
      g: 2,
      b: 65,
    },
  },
  graph_min_TL: 30,
  graph_max_TL: 100,
  form_validator: {
    pos_emitter_x: false,
    pos_emitter_y: false,
    pos_emitter_z: false,
    pos_receiver_x: false,
    pos_receiver_y: false,
    pos_receiver_z: false,
    f_ini: false,
    f_end: false,
    freqs_in_range: false,
    correct_distance: false
  },
  max_allowed_freq: 20000,
  min_allowed_freq: 20,
  max_allowed_distance: 100000,
  current_distance: 0,
  selected_point: {
    x: null,
    y: null,
  },
  job_id: '',
  job_stored: false,
  job_name: null,
  job_list: [],
  showModal: false,
  sea_properties: {}
});

const mutations = {
  set_pos_emitter_x(state, data) {
    state.pos_emitter.x = parseFloat(data);
  },
  set_pos_emitter_y(state, data) {
    state.pos_emitter.y = parseFloat(data);
  },
  set_pos_emitter_z(state, data) {
    state.pos_emitter.z = parseFloat(data);
  },
  set_pos_receiver_x(state, data) {
    state.pos_receiver.x = parseFloat(data);
  },
  set_pos_receiver_y(state, data) {
    state.pos_receiver.y = parseFloat(data);
  },
  set_pos_receiver_z(state, data) {
    state.pos_receiver.z = parseFloat(data);
  },
  set_cartesian_or_geographic(state, data) {
    state.cartesian_or_geographic = data;
  },
  set_pos_receiver_cartesian_x(state, data) {
    state.pos_receiver_cartesian.x = parseFloat(data);
  },
  set_pos_receiver_cartesian_y(state, data) {
    state.pos_receiver_cartesian.y = parseFloat(data);
  },
  //// Indicamos en el state si se ha modificado geographic o cartesian
  set_last_pos_type_modified(state, data) {
    state.last_pos_type_modified = data;
  },
  set_f_ini(state, data) {
    state.f_ini = parseFloat(data);
  },
  set_f_end(state, data) {
    state.f_end = parseFloat(data);
  },
  set_f_step(state, data) {
    state.f_step = data;
  },
  set_model(state, data) {
    state.model = data;
  },
  set_bathymetry(state, data) {
    state.bathymetry = data;
  },
  set_transect_1(state, data) {
    state.result.transect_1.rR = data.rR;
    state.result.transect_1.zR = data.zR;
    state.result.transect_1.f = data.f;
    state.result.transect_1.TL = data.TL;
  },
  set_transect_2(state, data) {
    state.result.transect_2.rR = data.rR;
    state.result.transect_2.f = data.f;
    state.result.transect_2.TL = data.TL;
  },
  set_selected_f(state, data) {
    state.selected_f = data;
  },
  set_graph_color(state, data) {
    state.graph_color = data;
  },
  set_graph_min_TL(state, data) {
    state.graph_min_TL = data;
  },
  set_graph_max_TL(state, data) {
    state.graph_max_TL = data;
  },
  set_validator(state, data) {
    state.form_validator[data[1]] = data[0];
  },
  set_current_distance(state, data) {
    state.current_distance = data
  },
  set_selected_point_x(state, data) {
    state.selected_point.x = data;
  },
  set_selected_point_y(state, data) {
    state.selected_point.y = data;
  },
  set_job_id(state, data) {
    state.job_id = data;
  },
  set_job_stored(state, data) {
    state.job_stored = data;
  },
  set_job_name(state, data) {
    state.job_name = data;
  },
  set_job_list(state, data) {
    state.job_list = data;
  },
  set_modal_state(state, data) {
    state.showModal = data;
  },
  set_sea_properties(state, data) {
    state.sea_properties = data;
  }
};

const actions = {
  change_pos_emitter_from_map({ dispatch, commit, getters }, latlng) {
    commit("set_pos_emitter_x", latlng.lng);
    commit("set_pos_emitter_y", latlng.lat);
    let datax = [String(latlng.lng), "pos_emitter_x"];
    dispatch("urn/validate", datax, { root: true });
    let datay = [String(latlng.lat), "pos_emitter_y"];
    dispatch("urn/validate", datay, { root: true });
    if (getters["last_pos_type_modified"] == "cartesian") {
      dispatch("getCoords");
    } else dispatch("getDistances");
  },
  change_pos_emitter_x({ dispatch, commit, getters }, x) {
    if (isNaN(parseFloat(x))) {
      // Aquí hay que comprobar que llege un numero, si llega otra cosa que guarde un 0.
      commit("set_pos_emitter_x", 0);
    } else {
      commit("set_pos_emitter_x", x);
      let data = [String(x), "pos_emitter_x"];
      dispatch("urn/validate", data, { root: true });
      if (getters["last_pos_type_modified"] == "cartesian") {
        dispatch("getCoords");
      } else dispatch("getDistances");
    }
  },
  change_pos_emitter_y({ dispatch, commit, getters }, y) {
    if (isNaN(parseFloat(y))) {
      // Aquí hay que comprobar que llege un numero, si llega otra cosa que guarde un 0.
      commit("set_pos_emitter_y", 0);
    } else {
      commit("set_pos_emitter_y", y);
      let data = [String(y), "pos_emitter_y"];
      dispatch("urn/validate", data, { root: true });
      if (getters["last_pos_type_modified"] == "cartesian") {
        dispatch("getCoords");
      } else dispatch("getDistances");
    }
  },
  change_pos_emitter_z({ dispatch, commit }, z) {
    if (isNaN(parseFloat(z))) {
      // Aquí hay que comprobar que llege un numero, si llega otra cosa que guarde un 0.
      commit("set_pos_emitter_z", 0);
    } else {
        commit("set_pos_emitter_z", z);
        let data = [String(z), "pos_emitter_z"];
        dispatch("urn/validate", data, { root: true });
    }
  },
  change_pos_receiver_x({ dispatch, commit }, x) {
    if (isNaN(parseFloat(x))) {
      // Aquí hay que comprobar que llege un numero, si llega otra cosa que guarde un 0.
      commit("set_pos_receiver_x", 0);
    } else {
               commit("set_pos_receiver_x", x);
               commit("set_last_pos_type_modified", "geographic");
               let data = [String(x), "pos_receiver_x"];
               dispatch("urn/validate", data, { root: true });
               dispatch("getDistances");
           }
  },
  change_pos_receiver_y({ dispatch, commit }, y) {
    if (isNaN(parseFloat(y))) {
      // Aquí hay que comprobar que llege un numero, si llega otra cosa que guarde un 0.
      commit("set_pos_receiver_y", 0);
    } else {
               commit("set_pos_receiver_y", y);
               commit("set_last_pos_type_modified", "geographic");
               let data = [String(y), "pos_receiver_y"];
               dispatch("urn/validate", data, { root: true });
               dispatch("getDistances");
           }
  },
  change_distances_from_coords_receiver_x({ commit }, x) {
    commit("set_pos_receiver_cartesian_x", x);
  },
  change_distances_from_coords_receiver_y({ commit }, y) {
    commit("set_pos_receiver_cartesian_y", y);
  },
  change_pos_receiver_z({ dispatch, commit }, z) {
    if (isNaN(parseFloat(z))) {
      // Aquí hay que comprobar que llege un numero, si llega otra cosa que guarde un 0.
      commit("set_pos_receiver_z", 0);
    } else {
               commit("set_pos_receiver_z", z);
               let data = [String(z), "pos_receiver_z"];
               dispatch("urn/validate", data, { root: true });
           }
  },
  change_pos_receiver_x_from_cartesian({ dispatch, commit }, x) {
    commit("set_pos_receiver_x", x);
    commit("set_last_pos_type_modified", "cartesian");
    let data = [String(x), "pos_receiver_x"];
    dispatch("urn/validate", data, { root: true });
  },
  change_pos_receiver_y_from_cartesian({ dispatch, commit }, y) {
    commit("set_pos_receiver_y", y);
    commit("set_last_pos_type_modified", "cartesian");
    let data = [String(y), "pos_receiver_y"];
    dispatch("urn/validate", data, { root: true });
  },
  change_cartesian_or_geographic({ commit }, cartesian_or_geographic) {
    commit("set_cartesian_or_geographic", cartesian_or_geographic);
  },
  change_pos_receiver_cartesian_x({ dispatch, commit }, x) {
    commit("set_pos_receiver_cartesian_x", x);
    dispatch("getCoords");
    let data = [x, "dist"];
    dispatch("urn/validate", data, { root: true });
  },
  change_pos_receiver_cartesian_y({ dispatch, commit }, y) {
    commit("set_pos_receiver_cartesian_y", y);
    dispatch("getCoords");
    let data = [y, "dist"];
    dispatch("urn/validate", data, { root: true });
  },
  change_f_ini({ dispatch, commit }, f_ini) {
    if (isNaN(parseFloat(f_ini))) {
      // Aquí hay que comprobar que llege un numero, si llega otra cosa que guarde un 0.
      commit("set_f_ini", 0);
    } else {
               commit("set_f_ini", f_ini);
               let data = [String(f_ini), "f_ini"];
               dispatch("urn/validate", data, { root: true });
           }
  },
  change_f_end({ dispatch, commit }, f_end) {
    if (isNaN(parseFloat(f_end))) {
      // Aquí hay que comprobar que llege un numero, si llega otra cosa que guarde un 0.
      commit("set_f_end", 0);
    } else {
    commit("set_f_end", f_end);
    let data = [String(f_end), "f_end"];
    dispatch("urn/validate", data, { root: true });
    }
  },
  change_f_step({ commit }, f_step) {
    commit("set_f_step", f_step);
  },
  change_model({ commit }, model) {
    commit("set_model", model);
  },
  change_bathymetry({ commit }, bathymetry) {
    commit("set_bathymetry", bathymetry);
  },
  change_transect_1({ commit }, transect_1) {
    commit("set_transect_1", transect_1);
  },
  change_transect_2({ commit }, transect_2) {
    commit("set_transect_2", transect_2);
  },
  change_selected_f({ commit }, selected_f) {
    commit("set_selected_f", selected_f);
  },
  change_graph_color({ commit }, graph_color) {
    commit("set_graph_color", graph_color);
  },
  change_graph_min_TL({ commit }, graph_min_TL) {
    commit("set_graph_min_TL", graph_min_TL);
  },
  change_graph_max_TL({ commit }, graph_max_TL) {
    commit("set_graph_max_TL", graph_max_TL);
  },
  change_selected_point_x({ commit }, x) {
    commit("set_selected_point_x", parseInt(x));
  },
  change_selected_point_y({ commit }, y) {
    commit("set_selected_point_y", parseInt(y));
  },
  change_job_id({ commit }, job_id) {
    commit("set_job_id", job_id)
  },
  change_job_stored({ commit }, job_stored) {
    commit("set_job_stored", job_stored)
  },
  change_job_name({ commit }, job_name) {
    commit("set_job_name", job_name)
  },
  change_job_list({ commit }, job_list) {
    commit("set_job_list", job_list)
  },
  change_sea_properties({ commit }, data) {
    commit("set_sea_properties", data)
  },
  change_modal_state({ commit }, status) {
    commit("set_modal_state", status)
  },

  validate({ dispatch, commit, getters }, data) {
    let payload = []
    if(data[1] != 'dist') {
      payload = [false, data[1]]
      if (data[0] && data[0].replace(/([^\d]*)(\d*(\.\d{0,2})?)(.*)/, "$2")) {
        payload[0] = true;
      }
      commit("set_validator", payload)

      // Check freqs
      if( payload[0] == true && ( (data[1] == 'f_end' && getters['form_validator'].f_ini) || (data[1] == 'f_ini' && getters['form_validator'].f_end) ) ){
        payload = [true, 'freqs_in_range']
        let f_ini = parseFloat(getters['f_ini'])
        let f_end = parseFloat(getters['f_end'])
        let f_max = parseFloat(getters['max_allowed_freq'])
        let f_min = parseFloat(getters['min_allowed_freq'])
        // console.log("f_ini: ", f_ini)
        // console.log("f_ini: ", f_end)
        // console.log("f_max: ", f_max)
        // console.log("f_min: ", f_min)
        if (f_end <= f_ini || f_end > f_max || f_ini < f_min) {
        //  console.log("FALSE")
          payload[0] = false      
        }
        else {
        //  console.log("TRUE")
          payload[0] = true 
        }
        commit("set_validator", payload)
      }
    }
    else {
      // Check distance
      if (!isNaN(getters['pos_receiver_cartesian'].x) && !isNaN(getters['pos_receiver_cartesian'].y)){
        //console.log("THE TWO ARE NUMBERS")
        if (getDistance(getters['pos_receiver_cartesian'].x, getters['pos_receiver_cartesian'].y) < getters['max_allowed_distance']) {
          payload = [true, 'correct_distance']
          //console.log("CORRECT DISTANCE: ", getDistance(getters['pos_receiver_cartesian'].x, getters['pos_receiver_cartesian'].y))
        }
        else {
          payload = [false, 'correct_distance']
          //console.log("INCORRECT DISTANCE: ", getDistance(getters['pos_receiver_cartesian'].x, getters['pos_receiver_cartesian'].y))
        }
        commit("set_validator", payload)
      }
      else {
        payload = [false, 'correct_distance']
        commit("set_validator", payload)
        //console.log("THE TWO ARE NOT NUMBERS")
      }
      commit('set_current_distance', getDistance(getters['pos_receiver_cartesian'].x, getters['pos_receiver_cartesian'].y))
    }

    // Check if activate "get properties" on next window change
    if(getters['form_validator'].correct_distance && getters['form_validator'].pos_emitter_z && getters['form_validator'].pos_receiver_z && data[1].substring(0, 3) == 'pos') {
      
      dispatch("ui/change_get_properties", true, { root: true });
    }
      
  },

  toast() {
    Vue.$toast.open({
      message: "Algo fue mal, comprueba todos los datos.",
      type: "error",
      position: "top",
      pauseOnHover: true,
      duration: 6000,
    });
  },

  // Calculate
  calculate({ dispatch, commit, getters, rootGetters }) {
    // Set 'loading' status
    dispatch("ui/change_current_interface", 0, { root: true });
    dispatch("ui/change_status", 2, { root: true });
    // Get bathymetry from Emodnet API
    let url =
      "https://rest.emodnet-bathymetry.eu/depth_profile?geom=LINESTRING(" +
      getters.pos_emitter.x +
      " " +
      getters.pos_emitter.y +
      "," +
      getters.pos_receiver.x +
      " " +
      getters.pos_receiver.y +
      ")";
    getBathymetry(url).then((res) => {
      let bty = [];
      res.data.forEach((position) => {
        if (position == null) {
          bty.push(0);
        } else {
          bty.push(position);
        }
      });
      commit("set_bathymetry", bty);

      // Calculate model
      const formData = new FormData();
      for (const key in getters) {
        if (key == "pos_emitter") {
          formData.append("pos_emitter_x", getters[key].x);
          formData.append("pos_emitter_y", getters[key].y);
          formData.append("pos_emitter_z", getters[key].z);
        } else if (key == "pos_receiver") {
          formData.append("pos_receiver_x", getters[key].x);
          formData.append("pos_receiver_y", getters[key].y);
          formData.append("pos_receiver_z", getters[key].z);
        } else if (key == "sea_properties") {
          formData.append("density", getters['sea_properties'].lecho[0]);
          formData.append("c_L", getters['sea_properties'].lecho[1]);
          formData.append("c_T", getters['sea_properties'].lecho[2]);
          formData.append("a_L", getters['sea_properties'].lecho[3]);
          formData.append("a_t", getters['sea_properties'].lecho[4]);
          formData.append("ssp", getters['sea_properties'].ssp);
        }
          else if (
            key != "models_list" &&
            key != "transect_1" &&
            key != "transect_2" &&
            key != "selected_f" &&
            key != "graph_color" &&
            key != "graph_min_TL" &&
            key != "graph_max_TL" &&
            key != "form_validator" &&
            key != "max_allowed_freq" &&
            key != "min_allowed_freq" &&
            key != "max_allowed_distance" &&
            key != "current_distance" &&
            key != "cartesian_or_geographic" &&
            key != "pos_receiver_cartesian" &&
            key != "selected_point_x" &&
            key != "selected_point_y" &&
            key != "last_pos_type_modified" && 
            key != "job_id" &&
            key != "job_stored" &&
            key != "job_name" &&
            key != "job_list"
          ) {
            formData.append(key, getters[key]);
          }
      }
      formData.append("user_id", rootGetters['user/user_id'])
      url = "noisemodels/calculate/";
      postData(url, formData)
        .then((res) => {
          if (res.status == "200") {
            //console.log("YEP!")
          } else {
            console.log(res.data)
            var errorMessage = "Something went wrong. Process stopped."
            if(res.data.error == 'max_simulations')
              errorMessage = 'Maximum number of simulations reached. Process stopped. '
            
            dispatch("ui/change_current_interface", 5, { root: true });
            dispatch("ui/change_status", 1, { root: true });
            Vue.$toast.open({
              message: errorMessage,
              type: "error",
              position: "top",
              pauseOnHover: true,
              duration: 6000,
            });
          }
        })
        .catch((err) => console.log(err));
    });
  },
  getCoords({ dispatch, commit, getters }) {
    if (
      getters["pos_emitter"].x &&
      getters["pos_emitter"].y &&
      (getters["pos_receiver_cartesian"].x ||
        getters["pos_receiver_cartesian"].y)
    ) {
      const url = "noisemodels/coords/";
      const formData = new FormData();
      formData.append("pos_emitter_x", getters["pos_emitter"].x);
      formData.append("pos_emitter_y", getters["pos_emitter"].y);
      formData.append("dist_x", getters["pos_receiver_cartesian"].x);
      formData.append("dist_y", getters["pos_receiver_cartesian"].y);
      postData(url, formData).then((res) => {
        dispatch(
          "change_pos_receiver_x_from_cartesian",
          res.data.pos_destination[1]
        );
        dispatch(
          "change_pos_receiver_y_from_cartesian",
          res.data.pos_destination[0]
        );
      });
    }
  },
  getDistances({ dispatch, commit, getters }) {
    if (
      getters["pos_emitter"].x &&
      getters["pos_emitter"].y &&
      getters["pos_receiver"].x &&
      getters["pos_receiver"].y
    ) {
      const url = "noisemodels/distances/";
      const formData = new FormData();
      formData.append("pos_emitter_x", getters["pos_emitter"].x);
      formData.append("pos_emitter_y", getters["pos_emitter"].y);
      formData.append("pos_receiver_x", getters["pos_receiver"].x);
      formData.append("pos_receiver_y", getters["pos_receiver"].y);
      postData(url, formData).then((res) => {
        dispatch(
          "change_distances_from_coords_receiver_x",
          res.data.distance_y.toFixed(0)
        );
        dispatch(
          "change_distances_from_coords_receiver_y",
          res.data.distance_x.toFixed(0)
        );
        let data = [0, "dist"];
        dispatch("urn/validate", data, { root: true });
      });
    }
  },
  get_job({ dispatch, commit }, job_id) {
    // Set 'loading' status
    dispatch("ui/change_current_interface", 0, { root: true });
    dispatch("ui/change_status", 2, { root: true });

    // Get previous simulation from DB
    const formData = new FormData()
    formData.append("job_id", job_id)
    postData("noisemodels/getjob/", formData)
    .then((res) => {
      if (res.status == "200") {
        commit("set_job_id", res.data.job_id);
        commit("set_job_name", res.data.job_name);
        commit("set_job_stored", res.data.stored);
        commit("set_pos_emitter_x", res.data.pos_emitter_x);
        commit("set_pos_emitter_y", res.data.pos_emitter_y);
        commit("set_pos_emitter_z", res.data.pos_emitter_z);
        dispatch("urn/change_pos_receiver_x_from_cartesian", res.data.pos_receiver_x, { root: true })
        dispatch("urn/change_pos_receiver_y", res.data.pos_receiver_y, { root: true })
        commit("set_pos_receiver_z", res.data.pos_receiver_z);
        commit("set_f_ini", res.data.f_ini);
        commit("set_f_end", res.data.f_end);
        commit("set_f_step", res.data.f_step);
        commit("set_model", res.data.model);
        commit("set_transect_1", res.data.transect_1);
        commit("set_transect_2", res.data.transect_2);

        // Validate fetched data, so user can run a new simulation
        let validateData = [String(res.data.pos_emitter_x), "pos_emitter_x"];
        dispatch("urn/validate", validateData, { root: true });
        validateData = [String(res.data.pos_emitter_y), "pos_emitter_y"];
        dispatch("urn/validate", validateData, { root: true });
        validateData = [String(res.data.pos_emitter_z), "pos_emitter_z"];
        dispatch("urn/validate", validateData, { root: true });
        validateData = [String(res.data.pos_receiver_x), "pos_receiver_x"];
        dispatch("urn/validate", validateData, { root: true });
        validateData = [String(res.data.pos_receiver_y), "pos_receiver_y"];
        dispatch("urn/validate", validateData, { root: true });
        validateData = [String(res.data.pos_receiver_z), "pos_receiver_z"];
        dispatch("urn/validate", validateData, { root: true });
        validateData = [String(res.data.f_ini), "f_ini"];
        dispatch("urn/validate", validateData, { root: true });
        validateData = [String(res.data.f_end), "f_end"];
        dispatch("urn/validate", validateData, { root: true });

        // Remove 'loading' status and display results
        dispatch("ui/change_current_interface", 8, { root: true });
        dispatch("ui/change_status", 1, { root: true });
      } else {
        dispatch("ui/change_current_interface", 5, { root: true });
        dispatch("ui/change_status", 1, { root: true });
        Vue.$toast.open({
          message: "Something went wrong. Process stopped.",
          type: "error",
          position: "top",
          pauseOnHover: true,
          duration: 6000,
        });
      }
    })
    .catch((err) => console.log(err));
  },
  get_job_list({ rootGetters, commit }) {
    const formData = new FormData();
    formData.append("user_id", rootGetters['user/user_id'])
    postData("noisemodels/list/", formData)
        .then((res) => {
          if (res.status == "200") {
            commit("set_job_list", res.data.Jobs)          
          }
        })
    // getData("noisemodels/list/").then(res => {
    //     commit("set_job_list", res.data.Jobs)
    // })
  },
  get_sea_properties({ dispatch, commit, getters }) {
    dispatch("ui/change_status", 2, { root: true });
    // Get bathymetry from Emodnet API
    let url =
      "https://rest.emodnet-bathymetry.eu/depth_profile?geom=LINESTRING(" +
      getters.pos_emitter.x +
      " " +
      getters.pos_emitter.y +
      "," +
      getters.pos_receiver.x +
      " " +
      getters.pos_receiver.y +
      ")";
    getBathymetry(url).then((res) => {
      let bty = [];
      res.data.forEach((position) => {
        if (position == null) {
          bty.push(0);
        } else {
          bty.push(position);
        }
      });
      commit("set_bathymetry", bty);
      const formData = new FormData();
      formData.append("pos_emitter_x", getters['pos_emitter'].x);
      formData.append("pos_emitter_y", getters['pos_emitter'].y);
      formData.append("pos_emitter_z", getters['pos_emitter'].z);

      formData.append("pos_receiver_x", getters['pos_receiver'].x);
      formData.append("pos_receiver_y", getters['pos_receiver'].y);
      formData.append("pos_receiver_z", getters['pos_receiver'].z);

      formData.append("bathymetry", getters['bathymetry']);

      url = "noisemodels/getproperties/";
      postData(url, formData)
        .then((res) => {
          if (res.status == "200") {
            commit("set_sea_properties", res.data);
            setTimeout(() => {
              dispatch("ui/change_status", 1, { root: true });
              dispatch("ui/change_get_properties", false, { root: true });
            }, 3000);
            
          }
        })
    })
  }
};

const getters = {
  models_list: (state) => state.models_list,
  pos_emitter: (state) => state.pos_emitter,
  pos_emitter_x: (state) =>
    isNaN(state.pos_emitter.x) ? 0 : state.pos_emitter.x,
  pos_emitter_y: (state) =>
    isNaN(state.pos_emitter.y) ? 0 : state.pos_emitter.y,
  pos_emitter_z: (state) =>
    isNaN(state.pos_emitter.z) ? 0 : state.pos_emitter.z,
  pos_receiver: (state) => state.pos_receiver,
  pos_receiver_x: (state) => isNaN(state.pos_receiver.x) ? 0 : state.pos_receiver.x,
  pos_receiver_y: (state) => isNaN(state.pos_receiver.y) ? 0 : state.pos_receiver.y,
  pos_receiver_z: (state) => isNaN(state.pos_receiver.z) ? 0 : state.pos_receiver.z,
  last_pos_type_modified: (state) => state.last_pos_type_modified,
  f_ini: (state) => isNaN(state.f_ini) ? 0 : state.f_ini,
  f_end: (state) => isNaN(state.f_end) ? 0 : state.f_end,
  f_step: (state) => isNaN(state.f_step) ? 0 : state.f_step,
  model: (state) => state.model,
  bathymetry: (state) => state.bathymetry,
  transect_1: (state) => state.result.transect_1,
  transect_2: (state) => state.result.transect_2,
  selected_f: (state) => state.selected_f,
  graph_color: (state) => state.graph_color,
  graph_min_TL: (state) => state.graph_min_TL,
  graph_max_TL: (state) => state.graph_max_TL,
  form_validator: (state) => state.form_validator,
  max_allowed_freq: (state) => state.max_allowed_freq,
  min_allowed_freq: (state) => state.min_allowed_freq,
  max_allowed_distance: (state) => state.max_allowed_distance,
  current_distance: (state) => state.current_distance,
  cartesian_or_geographic: (state) => state.cartesian_or_geographic,
  pos_receiver_cartesian: (state) => state.pos_receiver_cartesian,
  selected_point_x: (state) => state.selected_point.x,
  selected_point_y: (state) => state.selected_point.y,
  job_id: (state) => state.job_id,
  job_stored: (state) => state.job_stored,
  job_name: (state) => state.job_name,
  job_list: (state) => state.job_list,
  showModal: (state) => state.showModal, 
  sea_properties: (state) => state.sea_properties
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  VueToast,
  Vuelidate,
};
