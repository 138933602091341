
import { getData } from '@/services/api'
import store from '@/store/index'
import Vue from "vue";

// PROPAGATION TOOL
export function modelName (model){
    let modelName = 'Unknown model'

    if(model == 'esferica') {
        modelName = '20·log(r) - Spherical divergence'
    }
    else if(model == 'cilindrica') {
        modelName = '10·log(x) - Cylindrical divergence'
    }
    else if(model == 'log19') {
        modelName = '19·log(r)'
    }
    else if(model == 'energetico') {
        modelName = 'Energy flux'
    }
    else if(model == 'colossus') {
        modelName = 'Shallow waters (Marsh-Schulkin)'
    }
    else if(model == 'reflexion_superficie') {
        modelName = 'Sea surface reflection'
    }
    else if(model == 'reflexion_superficie_lecho') {
        modelName = 'Sea surface - bottom reflection'
    }
    else if(model == 'bellhop') {
        modelName = 'Ray tracing'
    }
    else if(model == 'kraken') {
        modelName = 'Normal modes'
    }
    else if(model == 'SimplePE') {
        modelName = 'Parabolic equation'
    }

    return modelName
}

export function toastIfNaN(val, message) {
  if (isNaN(parseFloat(val)) && val != "") {
    Vue.$toast.open({
      message: message,
      type: "error",
      position: "top",
      pauseOnHover: true,
      duration: 2000,
    });
  }
}

export function getDistance (dist_x, dist_y) {
    return Math.sqrt(parseFloat(dist_x) * parseFloat(dist_x) + parseFloat(dist_y) * parseFloat(dist_y))
}
        

            
        








// AUREAL ¿¿¿¿?????
export function capitalizeFirstLetter(string) {
    if(string)
        return string.charAt(0).toUpperCase() + string.slice(1);
    else
        return '-------'
}

export function timestampToDate (timestamp) {
    var day = new Date(timestamp);
    var year = day.getFullYear()
    var month = day.getMonth() + 1;
    if(month <= 9) month = '0' + month 
    var date = day.getDate();
    if(date <= 9) date = '0' + date 
    var hour = day.getHours();
    if(hour <= 9) hour = '0' + hour
    var min = day.getMinutes();
    if(min <= 9) min = '0' + min
    var time = date + '-' + month + '-' + year + ' ' + hour + ':' + min;

    return time
}

export function nameToID(name) {
    switch (name) {
        case 'SOLICITUD DE RESERVA':
            return '001'
        case 'CREACIÓN DE EXPEDIENTE':
            return '002'
        case 'ASIGNACIÓN DE CONTENEDOR':
            return '003'
        case 'AVISO A TRANSPORTISTA':
            return '004'
        case 'ORDEN DE RECOGIDA':
            return '005'
        case 'RECOGIDA CONTENEDOR VACÍO':
            return '006'
        case 'SALIDA DEL PUERTO':
            return '007'
        case 'PRECINTADO DE CONTENEDOR':
            return '008'
        case 'DOCUMENTACIÓN DE EXPEDIENTE':
            return '009'
        case 'SOLICITUD ENTRADA M. PELIGROSA':
            return '010'
        case 'AUTORIZACIÓN M. PELIGROSA':
            return '011'
        case 'ENTRADA A TERMINAL':
            return '012'
        case 'LLEGADA A PESAJE':
            return '013'
        case 'PESAJE VGM':
            return '014'
        case 'CONTENEDOR POSICIONADO':
            return '015'
        case 'DUA SOLICITUD':
            return '016'
        case 'AUTORIZACIÓN LEVANTE':
            return '017'
        case 'CARGADO EN BUQUE':
            return '018'
        case 'REGISTRO BILL OF LADING':
            return '019'
       default:
            return '000'
   }
}

export function getExpedientStatus (actions) {
    var status = {
        'state': '',
        'last_update': null
    }
    actions.forEach(action => {
        if (action.status == 'pending' ){
            status.state = String(action.type)
        }
        
        else{
            if(status.last_update == null){
                status.last_update = new Date(action.status)
            }
            else {
                if(new Date(action.status) > status.last_update){
                    status.last_update = new Date(action.status)
                }
            }
        }
    });
    return status  
}

export function actionApiToFront (action) {
    var returnObj = {
        'id': '',
        'modified': '',
        'name': '',
        'status': '',
        'role': '',
        'stage': {
            'id': '',
            'name': ''
        },
        'type': '',
        'apiId': ''
    }
    console.log(action)
    switch (action.type) {
        case 'SOLICITUD_DE_RESERVA':
            returnObj.stage.id = '1'
            returnObj.id = '001'         
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'CREACION_DE_EXPEDIENTE':
            returnObj.stage.id = '1'
            returnObj.id = '002'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'ASIGNACION_DE_CONTENEDOR':
            returnObj.stage.id = '1'
            returnObj.id = '003'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'AVISO_A_TRANSPORTISTA':
            returnObj.stage.id = '2'
            returnObj.id = '004'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj          
        case 'ORDEN_DE_RECOGIDA':
            returnObj.stage.id = '2'
            returnObj.id = '005'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj 
        case 'RECOGIDA_CONTENEDOR_VACIO':
            returnObj.stage.id = '2'
            returnObj.id = '006'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj 
        case 'SALIDA_DEL_PUERTO':
            returnObj.stage.id = '2'
            returnObj.id = '007'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj 
        case 'PRECINTADO_DE_CONTENEDOR':
            returnObj.stage.id = '3'
            returnObj.id = '008'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj 
        case 'DOCUMENTACION_DE_EXPEDIENTE':
            returnObj.stage.id = '3'
            returnObj.id = '009'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj 
        case 'SOLICITUD_ENTRADA_M_PELIGROSA':
            returnObj.stage.id = '3'
            returnObj.id = '010'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj 
        case 'AUTORIZACIÓN_M_PELIGROSA':
            returnObj.stage.id = '3'
            returnObj.id = '011'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'ENTRADA_A_TERMINAL':
            returnObj.stage.id = '3'
            returnObj.id = '012'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'LLEGADA_A_PESAJE':
            returnObj.stage.id = '3'
            returnObj.id = '013'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'PESAJE VGM':
            returnObj.stage.id = '3'
            returnObj.id = '014'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'CONTENEDOR_POSICIONADO':
            returnObj.stage.id = '3'
            returnObj.id = '015'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'DUA_SOLICITUD':
            returnObj.stage.id = '3'
            returnObj.id = '016'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'AUTORIZACIÓN_LEVANTE':
            returnObj.stage.id = '3'
            returnObj.id = '017'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'CARGADO_EN_BUQUE':
            returnObj.stage.id = '4'
            returnObj.id = '018'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        case 'REGISTRO_BILL_OF_LADING':
            returnObj.stage.id = '4'
            returnObj.id = '019'    
            returnObj.apiId = action.id
            if (action.status == 'pending' ){
                returnObj.status = 'pending'
            }
            else {
                returnObj.status = 'completed'      
                returnObj.modified = action.status             
            }
            return returnObj
        default:
            return '000'
    }   
}


export function b64toBlob(dataURI,dataType) {

    var byteString = atob(dataURI);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: dataType });
}

export function statusName(name) {
    switch (name) {
        case 'SOLICITUD_DE_RESERVA':
            return 'SOLICITUD DE RESERVA'
        case 'CREACION_DE_EXPEDIENTE':
            return 'CREACIÓN DE EXPEDIENTE'
        case 'ASIGNACION_DE_CONTENEDOR':
            return 'ASIGNACIÓN DE CONTENEDOR'
        case 'AVISO_A_TRANSPORTISTA':
            return 'AVISO A TRANSPORTISTA'
        case 'ORDEN_DE_RECOGIDA': //*
            return 'ORDEN DE RECOGIDA'
        case 'RECOGIDA_CONTENEDOR_VACIO': //*
            return 'RECOGIDA CONTENEDOR VACÍO'
        case 'SALIDA_DEL_PUERTO':
            return 'SALIDA DEL PUERTO'
        case 'PRECINTADO_DE_CONTENEDOR': //*
            return 'PRECINTADO DE CONTENEDOR'
        case 'DOCUMENTACION_DE_EXPEDIENTE': //*** Recogemos testigo aquí
            return 'DOCUMENTACIÓN DE EXPEDIENTE'
        case 'SOLICITUD_ENTRADA_M_PELIGROSA':
            return 'SOLICITUD ENTRADA M. PELIGROSA'
        case 'AUTORIZACIÓN_M_PELIGROSA':
            return 'AUTORIZACIÓN M. PELIGROSA'
        case 'ENTRADA_A_TERMINAL': //*
            return 'ENTRADA A TERMINAL'
        case 'LLEGADA_A_PESAJE':
            return 'LLEGADA A PESAJE'
        case 'PESAJE_VGM': //*
            return 'PESAJE VGM'
        case 'CONTENEDOR_POSICIONADO':
            return 'CONTENEDOR POSICIONADO'
        case 'DUA_SOLICITUD':
            return 'DUA SOLICITUD'
        case 'AUTORIZACIÓN_LEVANTE':
            return 'AUTORIZACIÓN LEVANTE'
        case 'CARGADO_EN_BUQUE':
            return 'CARGADO EN BUQUE'
        case 'REGISTRO_BILL_OF_LADING':
            return 'REGISTRO BILL OF LADING'
       default:
            return ''
   }
}

export function defaultActions () {
    var defaultActions = {
        stage1: {
            id: '1',
            name: 'Apertura de expediente',
            action_list: {
            action001: {
                id: '001',
                name: 'SOLICITUD DE RESERVA',
                status: 'completed', // completed / pending / disabled
                modified: '',
                role: 'cargador',
                type: 'action',
                apiId: ''
            },
            action002: {
                id: '002',
                name: 'CREACIÓN DE EXPEDIENTE',
                status: '',
                modified: '',
                role: 'transitario',
                type: 'action',
                apiId: ''
            },
            action003: {
                id: '003',
                name: 'ASIGNACIÓN DE CONTENEDOR',
                status: '',
                modified: '',
                role: 'naviera',
                type: 'action',
                apiId: ''
            }
            }       
        },
        stage2: {
            id: '2',
            name: 'Recogida de contenedor vacío',
            action_list: {
            action004: {
                id: '004',
                name: 'AVISO A TRANSPORTISTA',
                status: '',
                modified: '',
                role: 'transitario',
                type: 'action',
                apiId: ''
            },
            action005: {
                id: '005',
                name: 'ORDEN DE RECOGIDA',
                status: '',
                modified: '',
                role: 'transportista',
                type: 'action',
                apiId: ''
            },
            action006: {
                id: '006',
                name: 'RECOGIDA CONTENEDOR VACÍO',
                status: '',
                modified: '',
                role: 'transportista',
                type: 'action',
                apiId: ''
            },
            action007: {
                id: '007',
                name: 'SALIDA DEL PUERTO',
                status: '',
                modified: '',
                role: 'transportista',
                type: 'event',
                apiId: ''
            }
            }       
        },
        stage3: {
            id: '3',
            name: 'Entrega de contenedor lleno',
            action_list: {
            action008: {
                id: '008',
                name: 'PRECINTADO DE CONTENEDOR',
                status: '',
                modified: '',
                role: 'cargador',
                type: 'action',
                apiId: ''
            },
            action009: {
                id: '009',
                name: 'DOCUMENTACIÓN DE EXPEDIENTE',
                status: '',
                modified: '',
                role: 'cargador',
                type: 'action',
                apiId: ''
            },
            action010: {
                id: '010',
                name: 'SOLICITUD ENTRADA M. PELIGROSA ',
                status: '',
                modified: '',
                role: 'naviera',
                type: 'action',
                apiId: ''
            },
            action011: {
                id: '011',
                name: 'AUTORIZACIÓN M. PELIGROSA',
                status: '',
                modified: '',
                role: 'validador',
                type: 'action',
                apiId: ''
            },
            action012: {
                id: '012',
                name: 'ENTRADA A TERMINAL',
                status: '',
                modified: '',
                role: 'transportista',
                type: 'event',
                apiId: ''
            },
            action013: {
                id: '013',
                name: 'LLEGADA A PESAJE',
                status: '',
                modified: '',
                role: 'transportista',
                type: 'event',
                apiId: ''
            },
            action014: {
                id: '014',
                name: 'PESAJE VGM',
                status: '',
                modified: '',
                role: 'transportista',
                type: 'action',
                apiId: ''
            },
            action015: {
                id: '015',
                name: 'CONTENEDOR POSICIONADO',
                status: '',
                modified: '',
                role: 'naviera',
                type: 'event',
                apiId: ''
            },
            action016: {
                id: '016',
                name: 'DUA SOLICITUD',
                status: '',
                modified: '',
                role: 'transitario',
                type: 'action',
                apiId: ''
            },
            action017: {
                id: '017',
                name: 'AUTORIZACIÓN LEVANTE',
                status: '',
                modified: '',
                role: 'validador',
                type: 'action',
                apiId: ''
            },
            }       
        },
        stage4: {
            id: '4',
            name: 'Carga del buque',
            action_list: {
            action018: {
                id: '018',
                name: 'CARGADO EN BUQUE',
                status: '',
                modified: '',
                role: 'naviera',
                type: 'event',
                apiId: ''
            },
            action019: {
                id: '019',
                name: 'REGISTRO BILL OF LADING',
                status: '',
                modified: '',
                role: 'naviera',
                type: 'action',
                apiId: ''
            }        
            }       
        },      
    }
    return defaultActions
}

export function updateContainerActions (container_id){
    getData('expedientes/' + container_id + '/actions/').then(res => {
      // SET STATE OF EACH ACTION IN VUEX
      setActionsState(res.data)
      
      // SET GLOBAL CONTAINER STATE IN VUEX
      var status = getExpedientStatus(res.data)
      var statusFixedName = statusName(status.state)
      store.commit('container/set_container_status', statusFixedName)
    });
}
function setActionsState (actions) {
    actions.forEach(action => {
      var actionsToDispatch = []
      var actionToDispatch = actionApiToFront(action)      
      actionsToDispatch.push(actionToDispatch)
      //console.log(actionsToDispatch)
      store.dispatch('container/actionsUpdate',actionsToDispatch)
      .then(resp => { console.log(resp) })
      .catch(err => { console.log(err) })
    });              
}

export function formatDate (inputDateString){
    var inputDate = new Date(inputDateString)

    var mm = inputDate.getMonth() + 1 // getMonth() is zero-based
    var dd = inputDate.getDate()
    var hh = inputDate.getHours()
    var mi = inputDate.getMinutes()
    var ss = inputDate.getSeconds()

    return [
            (dd>9 ? '' : '0') + dd,
            (mm>9 ? '' : '0') + mm,       
            inputDate.getFullYear()     
        ].join('-') 
        + '  ' + 
        [
            (hh>9 ? '' : '0') + hh,
            (mi>9 ? '' : '0') + mi,       
            (ss>9 ? '' : '0') + ss,                 
        ].join(':')
}