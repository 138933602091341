<template>
  <div>
    <div class="card-header-1">PROPAGATION MODEL</div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(3)"> &lt; </div>
        <div class="col-sm-10">

          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>

          <div class="row h-75">
            <div class="col-sm-4 my-auto"></div>

            <div class="col-sm-4 my-auto">
              <div class="input-label">Propagation model selection</div>
              <select v-model="model" class="select-input">
                  <option v-for="(model_item, index) in models_list" v-bind:key="index" :value="model_item">{{ modelCommonName(model_item) }}</option>
              </select>
            </div> 

            <div class="col-sm-4 my-auto"></div>
          </div>
          
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(5)"> &gt; </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex"

// Components and services
import navigation from '@/components/URN/navigation.vue'
import { modelName } from '@/services/helper'

export default {
  name: 'input4Model',
  components: {
    navigation
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      models_list: 'urn/models_list'
    }),
    model: {
          set (val) { this.$store.dispatch('urn/change_model', val); },
          get () { return this.$store.state.urn.model }
    },
  },
  data() {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    modelCommonName: function (model) {
        return modelName(model)
    }
  }
}
</script>