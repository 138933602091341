<template>
  <div class="results-container">
    <div v-if="job_name" class="card-header-1 header-thin">{{ job_name }}</div>
    <div v-else class="card-header-1 header-thin">RESULTS</div>
    <div class="card-body-1 results-container-body">  
          <!-- Graph 1 -->
          <div style="flex: 1;" ref="graph">
            <results-display v-if="graphs_visible" :height="this.graphs_height"></results-display>
          </div>          
          <div class="separator"></div>

          <!-- Graph 2 -->
          <div style="flex: 1;">
            <results-display2 v-if="graphs_visible" :height="this.graphs_height"></results-display2>
          </div>
          <div class="separator"></div>

          <!-- Bottom row options -->
          <div class="row pl-3 pr-3">
            <div>
              Frequency:
              <select v-model="selected_f" class="select-input limited-width">
                <option v-for="(frecuencia, index) in transect_1.f" v-bind:key="index" v-bind:value="index">{{ frecuencia.toFixed(2) }} Hz</option>
              </select>
            </div>

            <div class="ml-auto" v-if="!job_stored">
              <input class="general-input" placeholder="Simulation name" type="text" v-model="job_name" style="width: auto;">
              <span class="button-standard" v-on:click="storeJob">Save simulation</span>
            </div>
          </div>

    </div> <!-- Close card-body -->
  </div> <!-- Close component div wrapper -->      
</template>

<script>
// Vuex
import { mapGetters } from "vuex"

// Components and services
import resultsDisplay from '@/components/URN/resultsDisplay.vue'
import resultsDisplay2 from '@/components/URN/resultsDisplay2.vue'

import { postData } from '@/services/api'

export default {
  name: 'resultsContainer',
  components: {
    resultsDisplay,
    resultsDisplay2
  },
  data() {
    return {
      graphs_visible: false,
      graphs_height: 200,
    }
  },
  computed: {
    ...mapGetters({
      transect_1: 'urn/transect_1',
      job_id: 'urn/job_id',
      job_stored: 'urn/job_stored'
    }),
    selected_f: {
        set (val) { this.$store.dispatch('urn/change_selected_f', val); },
        get () { return this.$store.state.urn.selected_f }
    },
    job_name: {
        set (val) { this.$store.dispatch('urn/change_job_name', val); },
        get () { return this.$store.state.urn.job_name }
    },
  },
  created() {
    window.addEventListener("resize", this.resizeWindow)
  },
  mounted() {
    this.setGraphsHeight()
  },
  methods: {
    storeJob: function () {
      const formData = new FormData()
      formData.append("job_id", this.job_id)
      if(this.job_name) {
        formData.append("job_name", this.job_name)
      }
      postData("noisemodels/store/", formData).then(res => {
        if (res.status == 200 && res.data.Stored == "ok") {
          this.$noty.success("Simulation results stored")
          this.$store.dispatch('urn/change_job_stored', true)
          if(!this.job_name) this.job_name = 'Simulation ' + this.job_id
          this.$store.dispatch('urn/get_job_list')
        } 
        else {
          this.$noty.error("Simulation could not be stored")
        }
      }).catch(error => {
        this.$noty.error("Simulation could not be stored")
      });
    },
    setGraphsHeight: function () {
      this.graphs_height = this.$refs.graph.clientHeight
      this.graphs_visible = true
    },
    resizeWindow: function () {
      globalThis = this
      this.graphs_visible = false
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(function(){
          globalThis.setGraphsHeight()
      }, 150);
    },
  }
}
</script>
<style scoped>
.limited-width {
  width: 200px;
  margin-left: 15px;
}
.separator {
  height: 8px;
  width: 100%;
  margin-bottom: 12px;
  border-bottom: 1px solid #cacaca;
}
</style>