<template>
  <div class="container-fluid p-0 home-bg">
    <div class="pt-2 row h-100">
      <div class="col-sm-4"></div>
      <div class="col-sm-4 my-auto">
        <div>
          <div class="card-header-1">What do you need to measure?</div>
          <div class="card-body-1">
            <!-- <router-link  class="button-standard" tag="div" to="/urn" exact>
              Underwater Radiated Noise (URN)
            </router-link> -->
            <div class="button-standard-disabled">Noise Impact</div>
            <div class="button-standard-disabled">Marine Traffic</div>
            <div class="button-standard-disabled">Environmental Impact</div>
          </div>
        </div>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {postData} from '@/services/api'

export default {
  name: 'Home',
  created () {
  },
  methods: {
    redirect(location){
      if(location == 'URN') this.$router.push('/urn')
    }
  }
}
</script>
