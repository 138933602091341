<template>
    <div class="modal fade  bd-example-modal-lg" id="management" tabindex="-1" role="dialog" aria-labelledby="managementLabel" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <table v-if="showUserList" class="table table-borderless  table-striped w-100">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr   v-for="item in userList"
                                v-bind:user="item"
                                v-bind:key="item.id"
                            >
                                <td>  
                                    <a @click="editUser(item)" class="link-1" >
                                        {{ item.username }}
                                    </a>
                                </td>
                                <td>
                                    {{ item.email }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <userEdit v-else  v-bind:user = user :personalProfile = false ></userEdit> 
                </div>
                <div  v-if="showUserList" class="row p-2">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-outline-secondary " @click="newUser()" >New user</button>
                    </div>
                    <div class="col-sm-6 text-right">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import userEdit from '@/components/user/userEdit.vue'
import { getData } from '@/services/api'

export default {
  name: 'management',
  components: {
    userEdit
  },
  data() {
    return {
        showUserList: true,
        userList: [],
        user: ''
    };
  },
  mounted () {

  },
   created () {
    this.getUser()
  },
  methods: {
      getUser() {
        getData('auth-jwt/getUsers/')
        .then(res => {
            this.userList = res.data
        })
      },
      newUser (){
        this.showUserList = false
        this.user = {}
      },
      editUser(user) {
          this.showUserList = false
          this.user = user
      }
  }
};
</script>

<style lang = "scss" scoped>
.modal-dialog {
  max-width: 850px;
}
</style>
