import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import URN from '../views/URN.vue'
import User from '../views/User.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: User,
  //   meta: {
  //     requiresNotAuth: true
  //   }
  // },
  {
    // path: '/urn',
    path: '/',
    name: 'URN',
    component: URN,
    // meta: {
    //   requiresAuth: true
    // }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresNotAuth)) {
//     if (!store.getters['user/isLoggedIn']) {
//       next()
//       return
//     }
//     next('/')
//   } else {
//     next()
//   }
// })

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters['user/isLoggedIn']) {
//       next()
//       return
//     }
//     next('login')
//   } else {
//     next()
//   }
// })


export default router
