<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <img class="header-icon" :src="require('@/assets/icons/logo.svg')" alt="Logo Saturn" style="height: 25px; width: auto; margin-right: 12px;" />
        Noise Propagation Tool
        <!-- <router-link  class="navbar-title" tag="a" to="/" exact style="width: 80%;">
            <div style="display: flex; flex-direction: row; width: 100%; align-items: center;">
              <a class=" mr-3 navbar-brand" style=" height: 70px;">
                <img class="header-icon" :src="require('@/assets/icons/Saturn-logo.png')" alt="Logo Saturn" style="height: 100%; width: auto;" />
              </a>
              <div style="flex: 1; height: 80px; display: flex; flex-direction: column;">
                <div>
                  <a class=" mr-3 navbar-brand" style="height: 35px; width: 80px;">
                      <img class="header-icon" :src="require('@/assets/icons/TSI-logo.png')" alt="Logo tsi" style="height: 100%; width: auto;" />
                  </a>
                  Propagation models tool for the assessment of PL for URN measurements
                </div>  
                <div>
                  <a class=" mr-3 navbar-brand" style="height: 35px; width: 80px;">
                      <img class="header-icon-small" :src="require('@/assets/icons/CTN-logo.png')" alt="Logo ctn" style="height: 100%; width: auto;" />
                  </a>
                  H2020 SATURN Project (WP2)
                </div>  
              </div>   
            </div> -->
            <!-- <a class=" mr-3 avbar-brand">
                <img class="header-icon" :src="require('@/assets/icons/Saturn-logo.png')" alt="Logo Saturn"/>
            </a>
            <a class=" mr-3 avbar-brand">
                <img class="header-icon" :src="require('@/assets/icons/TSI-logo.png')" alt="Logo tsi"/>
            </a>
            <a class=" mr-3 avbar-brand">
                <img class="header-icon-small" :src="require('@/assets/icons/CTN-logo.png')" alt="Logo ctn"/>
            </a>
            Propagation Modelling Tool to obtain propagation losses. H2020 SATURN project (WP2)-->
        <!-- </router-link> -->
        <!-- Right side of Navbar -->
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="nav navbar-nav navbar-right ml-auto ">
            <li class="nav-item dropdown mr-5 nav-inicio">
              <a v-if="isLoggedIn" id="navbarDropdown" class=" dropdown-toggle link-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <!-- <img :src="require('@/assets/icons/icon-user.svg')" alt="Profile picture" class="profile-picture"/> -->
                <p v-if="fullname != ''" class="d-inline-block nav-user m-0 link-1">{{fullname}}</p>
                <p v-else class="d-inline-block nav-user m-0 link-1">{{username}}</p>
              </a>
              <router-link  v-else-if="this.$route.name != 'Login'" tag="a" to="/login" exact> -->
                <img :src="require('@/assets/icons/icon-user.svg')" alt="Profile picture" class="profile-picture"/>
                <a class="d-inline-block nav-user">Log in</a>
              </router-link>
              <!-- User options pop up -->
              <div ref="dropdownuser" class="dropdown-menu dropdown-menu-right dropdown-user" aria-labelledby="navbarDropdown">
                <div class="popup-body">
                  <div class="dropdown-item modal-call pointer"  data-toggle="modal" data-target="#history" > &nbsp;Simulations history</div>
                  <!-- <div class="line-separator"><hr /></div>
                  <div v-if="is_admin == true || is_admin == 'true'" class="dropdown-item modal-call pointer" data-toggle="modal" data-target="#management"> Users Management</div>
                  <div v-if="is_admin == true || is_admin == 'true'" class="line-separator"><hr /></div>
                  <div class="dropdown-item modal-call pointer"  data-toggle="modal" data-target="#userProfile" > &nbsp;User profile</div> -->
                  <div class="line-separator"><hr /></div>
                  <div>
                    <div  @click="logout" class="dropdown-item pointer">
                      &nbsp;Logout
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="navbar-underline"></div>
    </nav>
    <history></history> 
    <management v-if="is_admin == true || is_admin == 'true'"></management> 
    <userProfile v-if="isLoggedIn == true || isLoggedIn == 'true'"></userProfile>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import management from '@/components/user/management.vue'
import userProfile from '@/components/user/userProfile.vue'
import history from '@/components/user/history.vue'

export default {
  name: "propagationHeader",
  components: {
    management,
    userProfile,
    history
  },
  data() {
    return {
    };
  },
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      fullname: 'fullname',
      is_admin: 'is_admin'
    }) 
  },
  methods:{
    // logout: function () {
    //   console.log("FFFFFFFFF")
    //   console.log(this.$refs.dropdownuser.$el.classList)
    //   console.log(this.$refs.dropdownuser)
    //   this.$refs.dropdownuser.$el.classList.remove('show')
    //   // this.$store.dispatch('user/logout')
    //   // .then(resp => { console.log(resp) })
    //   // .catch(err => err)
    // },
    logout: function() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          //this.$router.push("/")
          console.log("OUT")
        })
        .catch(err => console.log(err));
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

.nav-inicio {
    align-content: center;
    display: grid
}

.profile-picture{
    width: 25px;
    padding-right: 5px;
}

.dropdown-user {
  z-index: 10000;
}



</style>
