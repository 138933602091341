<template>
  <div>
    <div class="card-header-1">RECEIVER POSITION</div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(1)"> &lt; </div>
        <div class="col-sm-10">

          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>

          <!--<div class="row">
            <div class="col-sm4" style="margin-top: 50px;">
              <select v-model="cartesian_or_geographic" class="select-input">
                <option value="cartesian" selected>Cartesian coordinates</option>
                <option value="geographic">Geographic coordinates</option>
              </select>
            </div>
          </div>-->
          <div class="row">
            <div class="col-sm-6" style="margin-bottom: 30px; margin-top: 30px; padding: 10px 40px; border-radius: 5px;" v-bind:class="{ darkened: last_pos_type_modified == 'cartesian' }">
              <div class="row my-auto">
                <div class="input-label">Longitude [º]</div>
                <input class="general-input form-control" :v="$v.x" placeholder="Longitude" type="text"  v-model="x" @blur="getBathymetry" v-bind:class="{ darkenedinput: last_pos_type_modified == 'cartesian' }">
                <!-- :disabled="x_cart != '' || y_cart != ''" -->
                <div v-if="!$v.x.decimal" class="col-form-label invalid col-sm-12">Longitude must be a number</div>
              </div>

              <div class="row my-auto">
                <div class="input-label">Latitude [º]</div>
                <input class="general-input form-control" :v="$v.y" placeholder="Latitude" type="text"  v-model="y" @blur="getBathymetry" v-bind:class="{ darkenedinput: last_pos_type_modified == 'cartesian' }">
                <!-- :disabled="x_cart != '' || y_cart != ''" -->
                <div v-if="!$v.y.decimal" class="col-form-label invalid col-sm-12">Latitude must be a number</div>
              </div>

              <div class="row my-auto">
                  <div class="input-label">Depth [m]</div>
                  <input class="general-input form-control" :v="$v.z" placeholder="Depth" type="text"  v-model="z" v-bind:class="{ darkenedinput: last_pos_type_modified == 'cartesian' }">
                  <div v-if="!$v.z.decimal" class="col-form-label invalid col-sm-12">Depth must be a number</div>
                  <div v-if="max_depth == null && $v.z.required && $v.z.decimal" class="col-form-label info col-sm-12">Set a valid point (long / lat) first</div>
                  <div v-else-if="!$v.z.maxValue && $v.z.required && $v.z.decimal" class="col-form-label invalid col-sm-12">Max depth in this point is {{ max_depth }} m</div>         
                  <div v-else-if="max_depth != null && $v.z.decimal" class="col-form-label info col-sm-12">Max depth in this point is {{ max_depth }} m</div>
              </div>  

              <div class="row my-auto">
                <div class="col-sm-3"></div>
                <div class="col-sm-6 text-center">
                  <div class="button-standard" v-on:click="navigate(7)" v-bind:class="{ darkenedinput: last_pos_type_modified == 'cartesian' }">Select on map</div>
                  <!-- v-if="x_cart == '' && y_cart == ''" -->
                </div>
                <div class="col-sm-3"></div>
              </div>      
            </div>

          

            <div class="col-sm-6" style="margin-bottom: 30px; margin-top: 30px; padding: 10px 40px; border-radius: 5px;" v-bind:class="{ darkened: last_pos_type_modified == 'geographic' }">
              <div class="row my-auto">
                <div class="input-label">X [m]</div>
                <input class="general-input" placeholder="Distance from source" type="text"  v-model="x_cart" @blur="getBathymetry" v-bind:class="{ darkenedinput: last_pos_type_modified == 'geographic' }">
              </div>

              <div class="row my-auto">
                <div class="input-label">Y [m]</div>
                <input class="general-input" placeholder="Distance from source" type="text"  v-model="y_cart" @blur="getBathymetry" v-bind:class="{ darkenedinput: last_pos_type_modified == 'geographic' }">
              </div>

              <div class="row my-auto" style="display-flex; flex-direction: column;">
                <div v-if=" current_distance != NaN && current_distance > max_allowed_distance" class="col-form-label invalid col-sm-12">Max Distance is 100Km. Current distance is {{ (current_distance / 1000).toFixed(0) }}Km</div>
              </div>

              <div class="row my-auto" style="display-flex; flex-direction: column;">
                <div>
                  <div class="input-label">Depth [m]</div>
                  <input class="general-input form-control" :v="$v.z" placeholder="Depth" type="text"  v-model="z" v-bind:class="{ darkenedinput: last_pos_type_modified == 'geographic' }">
                </div>
                <div>
                  <div v-if="!$v.z.decimal" class="col-form-label invalid col-sm-12">Depth must be a number</div>
                  <div v-if="max_depth == null && $v.z.required && $v.z.decimal" class="col-form-label info col-sm-12">Set a valid point (X / Y) first</div>
                  <div v-else-if="!$v.z.maxValue && $v.z.required && $v.z.decimal" class="col-form-label invalid col-sm-12">Max depth in this point is {{ max_depth }} m</div>         
                  <div v-else-if="max_depth != null && $v.z.decimal" class="col-form-label info col-sm-12">Max depth in this point is {{ max_depth }} m</div>
                </div>
              </div>       
            </div>
          </div>



        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(3)"> &gt; </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Components and services
import navigation from '@/components/URN/navigation.vue'
import { required, decimal, maxValue } from 'vuelidate/lib/validators'
import { getBathymetry } from '@/services/api'
import { toastIfNaN } from '@/services/helper'

export default {
  name: 'input2Destination',
  components: {
    navigation
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      pos_emitter: 'urn/pos_emitter',
      pos_receiver: 'urn/pos_receiver',
      max_allowed_distance: 'urn/max_allowed_distance',
      current_distance: 'urn/current_distance',
      last_pos_type_modified: 'urn/last_pos_type_modified'
    }),
    x: {
          set (val) { 
            toastIfNaN(val, "Longitude must be a number");
            this.$store.dispatch('urn/change_pos_receiver_x', val) ; },
          get () { return this.$store.state.urn.pos_receiver.x }
    },
    y: {
          set (val) { 
            toastIfNaN(val, "Longitude must be a number");
            this.$store.dispatch('urn/change_pos_receiver_y', val); },
          get () { return this.$store.state.urn.pos_receiver.y}
    },
    z: {
          set (val) { 
            toastIfNaN(val, "Longitude must be a number");
            this.$store.dispatch('urn/change_pos_receiver_z', val); },
          get () { return this.$store.state.urn.pos_receiver.z }
    },
    cartesian_or_geographic: {
          set (val) { this.$store.dispatch('urn/change_cartesian_or_geographic', val); },
          get () { return this.$store.state.urn.cartesian_or_geographic }
    },
    x_cart: {
          set (val) { this.$store.dispatch('urn/change_pos_receiver_cartesian_x', val); },
          get () { return this.$store.state.urn.pos_receiver_cartesian.x }
    },
    y_cart: {
          set (val) { this.$store.dispatch('urn/change_pos_receiver_cartesian_y', val); },
          get () { return this.$store.state.urn.pos_receiver_cartesian.y }
    },
  },
  data() {
    return {
      max_depth: null   
    }
  },
  validations: {
      x: {required, decimal},
      y: {required, decimal},
      z: {
          required, 
          decimal,
          maxValue (value) {
            return maxValue(this.max_depth)(value)
          }
      },
      max_distance: {
          maxValue (value) {
            return maxValue(this.max_allowed_distance)(value)
          }
      }
  }, 
  created () {
    this.getBathymetry()
  },
  mounted () {

  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    getBathymetry: function () {
      if(this.$v.x.required && this.$v.x.decimal && this.$v.y.required && this.$v.y.decimal){
        // Get bathymetry from Emodnet API
        let url = 'https://rest.emodnet-bathymetry.eu/depth_sample?geom=POINT(' +
                  this.pos_receiver.x + ' ' + this.pos_receiver.y + ')'          
        getBathymetry(url)
        .then(res => {
          if (res.data.avg != undefined && res.data.avg <= 0) {
            this.max_depth = -res.data.avg
          }
          else {
            this.max_depth = null
          }
        })
      }
      else {
        this.max_depth = null
      }
    }
  }
}
</script>
<style scoped>
  .darkened {
    background-color: #5b5b5b;
  }
  .darkenedinput {
    background-color: rgb(100, 100, 100) !important;
    color: rgb(141, 141, 141) !important;
  }
</style>