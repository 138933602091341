<template>
  <div>
    <!--<Plotly :data="data" :layout="layout" :display-mode-bar="true" :scrollZoom="true" :displaylogo="false"></Plotly>-->
    <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" id="plotly2" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'resultsDisplay2',
  components: {
    Plotly
  },
  data () {
    return {
      data: [
        {
          x: [],
          y: [],
          mode: 'lines',
          //hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 2
          },
          hovertemplate: 'PL: %{y:.2f}<extra></extra>',
        }
      ],
      layout:{
        yaxis: {
          ticks: 'outside',
          autorange: 'reversed',
          title: {
            text: 'PL [dB re 1 µPa]'
          }
        },
        xaxis: {
          ticks: 'outside',
          title: {
            text: 'Radial distance [m]'
          }
        },
        plot_bgcolor:"#052030",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        //autosize: true,
        height: 200,
        margin: {
          t: 10,
          r: 160,
          b: 40
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      }
    }  
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      transect_2: 'urn/transect_2',
      bathymetry: 'urn/bathymetry',
      pos_emitter: 'urn/pos_emitter',
      pos_receiver: 'urn/pos_receiver',
      selected_f: 'urn/selected_f',
    }),
    selected_point_x: {
      set (val) { this.$store.dispatch('urn/change_selected_point_x', val); },
      get () { return this.$store.state.urn.selected_point.x }
    },
    selected_point_y: {
      set (val) { this.$store.dispatch('urn/change_selected_point_y', val); },
      get () { return this.$store.state.urn.selected_point.y }
    },
  },
  props: {
    height: Number
  },
  created() {
    this.layout.height = this.height
  },
  mounted () {
    // Draw Graph
    this.drawGraph()

    // Create listener to handle click event (To display freqs graph)
    let globalThis = this
    document.getElementById('plotly2').on('plotly_click', function(data){
      globalThis.setSelectedPoint(data)
    })
  },
  watch: {
    selected_f: function () {
      // When a frequency is selected, graph is re-drawn
      this.drawGraph()
    },
  },
  methods: {
    drawGraph: function () {
      this.data[0].x = []
      for(let i = 10; i < this.transect_2.rR.length; i++){
        this.data[0].x.push(this.transect_2.rR[i])
      }
      this.data[0].y = []
      for(let i = 10; i < this.transect_2.rR.length; i++){
        this.data[0].y.push(this.transect_2.TL[i][this.selected_f])
      }
    },
    setSelectedPoint: function (data) {
      this.selected_point_x = this.transect_2.rR.indexOf(data.points[0].x)
    }
  }
}
</script>